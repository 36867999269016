// file: DriveSearch.tsx
import React, { useState, ChangeEvent } from "react";

interface DriveSearchProps {
  onSearch: (query: string) => void;
  placeholder?: string;
}

/**
 * A reusable search box for filtering drive items by name.
 */
const DriveSearch: React.FC<DriveSearchProps> = ({ onSearch, placeholder }) => {
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    onSearch(value);
  };

  return (
    <div className="mb-2">
      <input
        type="text"
        value={searchValue}
        onChange={handleChange}
        placeholder={placeholder || "Search..."}
        className="border border-gray-300 rounded-md p-1.5 w-64 text-sm"
      />
    </div>
  );
};

export default DriveSearch;

// file: DriveParticipant.tsx
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Participant,
  ViewMode,
  DriveItem,
} from "../../../../../interfaces/caseFileDrive/DriveFiles";
import { getDriveData } from "../../../../../services/fileServices/FileService";
import { useAuth } from "../../../../../firebase/AuthProvider";

// We'll import our mapper
import DriveList from "../driveItems/DriveList";
import { mapYearsToDriveItems } from "../../../../../utilities/MapFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faList } from "@fortawesome/free-solid-svg-icons";
import { useDriveContext } from "../../../../../contexts/DriveContext";
import DriveSearch from "../driveItems/DriveSearch";

const DriveParticipant: React.FC = () => {
  const { currentUser, currentRoles } = useAuth();
  const { participantId } = useParams();
  const navigate = useNavigate();

  const [participant, setParticipant] = useState<Participant | null>(null);
  const [items, setItems] = useState<DriveItem[]>([]); // for DriveList
  const [searchTerm, setSearchTerm] = useState<string>(""); // for filtering
  const { viewMode, setViewMode } = useDriveContext();
  const [loading, setLoading] = useState<boolean>(true);
  const hasHigherRoleThanSC = currentRoles?.some((role) => role.name !== "SC");
  const { employeeId } = useParams<{ employeeId?: string }>();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        if (currentUser) {
          const uid = await currentUser.getIdToken();
          let data;
          if (employeeId && hasHigherRoleThanSC)
            data = await getDriveData(uid, employeeId);
          else data = await getDriveData(uid); // data is an array of Participant
          const found = data.find(
            (p) => p.participantId.toString() === participantId
          );

          if (found) {
            setParticipant(found);
            // Convert the participant's years to DriveItem[] (folders)
            const yearItems = mapYearsToDriveItems(found.years);
            setItems(yearItems);
          } else {
            setParticipant(null);
            setItems([]);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [participantId, currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!participant) {
    return (
      <div>
        <p>Participant not found.</p>
        <button
          className="border rounded-lg p-1 shadow-sm hover:shadow-none mb-2 text-xs bg-white"
          onClick={() => navigate(`/drive/caseFileDrive/${employeeId ? "employee/" + employeeId : "" }`)}
        >
          {"<"} Back
        </button>
      </div>
    );
  }

  // Filter items by searchTerm (case-insensitive by name)
  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle user clicking on a "year" folder
  const handleItemClick = (item: DriveItem) => {
    if (item.isFolder) {
      // We interpret the folder "name" or "id" as the year
      const yearClicked = item.id;
      navigate(`${yearClicked}`);
    } else {
      // It's a file, if that ever happens here
      console.log("Clicked a file in participant-level view:", item.name);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-start align-middle">
        <button
          className="border rounded-lg p-1 shadow-sm hover:shadow-none mb-2 text-xs bg-white"
          onClick={() => navigate(`/drive/caseFileDrive/${employeeId ? "employee/" + employeeId : "" }`)}
        >
          {"<"} Back
        </button>
        {/* Toggle for grid/table */}
        <div className="flex flex-col">
          <div className="flex justify-end">
            <DriveSearch
              onSearch={(query) => setSearchTerm(query)}
              placeholder="Search folders..."
            />
          </div>
          <div className="flex gap-2 mb-2 justify-end">
            Display:
            <button
              className={
                viewMode === "grid"
                  ? "bg-[#3DD598] text-white w-7 h-7"
                  : "bg-gray-200 w-7 h-7"
              }
              onClick={() => setViewMode("grid")}
            >
              <FontAwesomeIcon icon={faGripVertical} />
            </button>
            <button
              className={
                viewMode === "table"
                  ? "bg-[#3DD598] text-white w-7 h-7"
                  : "bg-gray-200 w-7 h-7"
              }
              onClick={() => setViewMode("table")}
            >
              <FontAwesomeIcon icon={faList} />
            </button>
          </div>
        </div>
      </div>
      <h2 className="text-text inter-font font-bold text-xl mb-3">
        {participant.participantName}
      </h2>

      {/* Render the participant's years as "folder" items */}
      <DriveList
        items={filteredItems}
        viewMode={viewMode}
        onItemClick={handleItemClick}
        onRightClickItem={() => {}}
      />
    </div>
  );
};

export default DriveParticipant;

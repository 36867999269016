// DriveList.tsx

import React from "react";
import DriveItemRenderer from "./DriveItemRenderer";
import { DriveItem, ViewMode } from "../../../../../interfaces/caseFileDrive/DriveFiles";

interface DriveListProps {
  items: DriveItem[];
  viewMode: ViewMode;
  onItemClick?: (item: DriveItem) => void;
  onRightClickItem?: (e: React.MouseEvent, item: DriveItem) => void;

  // For rename
  renameTarget?: DriveItem | null;
  renameValue?: string;
  setRenameValue?: (val: string) => void;
  doRename?: (newName: string) => void;
}

const DriveList: React.FC<DriveListProps> = ({
  items,
  viewMode,
  onItemClick,
  onRightClickItem,
  renameTarget,
  renameValue,
  setRenameValue,
  doRename
}) => {
  const hasFiles = items.some((item) => !item.isFolder);

  if (viewMode === "grid") {
    return (
      <div className="flex flex-wrap flex-grow gap-4">
        {items.map((item) => (
          <DriveItemRenderer
            key={item.id}
            item={item}
            viewMode="grid"
            onItemClick={onItemClick}
            onRightClickItem={onRightClickItem}
            // rename props
            renameTarget={renameTarget}
            renameValue={renameValue}
            setRenameValue={setRenameValue}
            doRename={doRename}
          />
        ))}
      </div>
    );
  } else {
    // "table" view
    return (
      <table className="min-w-full text-left inter-font">
        <thead className="text-xs">
          <tr>
            <th className="text-center" style={{ width: "40px" }}>Icon</th>
            <th>Name</th>
            {hasFiles ? <th>Owner</th> : <th></th>}
            <th className="text-end">Last Updated</th>
            <th className="text-center">File Size</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <DriveItemRenderer
              key={`${item.isFolder ? 'folder-' : 'file-'}${item.id}`}
              item={item}
              viewMode="table"
              onItemClick={onItemClick}
              onRightClickItem={onRightClickItem}
              // rename props
              renameTarget={renameTarget}
              renameValue={renameValue}
              setRenameValue={setRenameValue}
              doRename={doRename}
            />
          ))}
        </tbody>
      </table>
    );
  }
};

export default DriveList;

// DriveItemRenderer.tsx

import React from "react";
import { DriveItem, ViewMode } from "../../../../../interfaces/caseFileDrive/DriveFiles";
import FolderItem from "./FolderItem";
import FileItem from "./FileItem";

interface DriveItemRendererProps {
  item: DriveItem;
  viewMode: ViewMode;
  onItemClick?: (item: DriveItem) => void;
  onRightClickItem?: (e: React.MouseEvent, item: DriveItem) => void;

  // Rename props
  renameTarget?: DriveItem | null;
  renameValue?: string;
  setRenameValue?: (val: string) => void;
  doRename?: (newName: string) => void;
}

const DriveItemRenderer: React.FC<DriveItemRendererProps> = ({
  item,
  viewMode,
  onItemClick,
  onRightClickItem,
  renameTarget,
  renameValue,
  setRenameValue,
  doRename
}) => {
  if (item.isFolder) {
    return (
      <FolderItem
        folder={item}
        viewMode={viewMode}
        onClick={() => onItemClick?.(item)}
        onRightClick={(e) => onRightClickItem?.(e, item)}
        renameTarget={renameTarget}
        renameValue={renameValue}
        setRenameValue={setRenameValue}
        doRename={doRename}
      />
    );
  } else {
    return (
      <FileItem
        file={item}
        viewMode={viewMode}
        onClick={() => onItemClick?.(item)}
        onRightClick={(e) => onRightClickItem?.(e, item)}
        renameTarget={renameTarget}
        renameValue={renameValue}
        setRenameValue={setRenameValue}
        doRename={doRename}
      />
    );
  }
};

export default DriveItemRenderer;

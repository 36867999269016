import React, { useEffect, useState } from "react";
import TemplateTask from "../../../../interfaces/templateTask/TemplateTask";
import Select, { ActionMeta, MultiValue, SingleValue } from "react-select";
import { useAuth } from "../../../../firebase/AuthProvider";
import {
  addUserToTaskGroup,
  customBatchRun,
  deleteTemplateTaskRequiredFile,
  getAllTemplateTasks,
  getTemplateTask,
  getTemplateTaskRequiredFiles,
  getTemplateTasks,
  removeUserFromTaskGroup,
  submitTaskToAddToGroup,
} from "../../../../services/templateService/TemplateTaskService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faEdit,
  faFile,
  faPencil,
  faTasks,
  faTrash,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  deleteTemplateTaskAction,
  getTemplateTaskActions,
  publishTemplateTaskTags,
} from "../../../../services/templateService/TemplateTaskActionService";
import TemplateTaskAction from "../../../../interfaces/templateTask/TemplateTaskAction";
import TemplateRequiresFile from "../../../../interfaces/templateTask/TemplateRequiresFile";
import AddTaskAction from "./AddTaskAction";
import AddRequiredFile from "./AddRequiredFile";
import TaskGroup from "../../../../interfaces/task/workflow/TaskGroup";
import AssigneeWithTooltip from "../../taskPage/AssigneeWithTooltip";
import TaskCreationLKValues from "../../../../interfaces/lkValues/TaskCreationLKValues";
import { getTaskLKValues } from "../../../../services/taskServices/TaskLKService";
import TemplateTaskShortResponse from "../../../../interfaces/templateTask/TemplateTaskShortResponse";
import { useNavigate } from "react-router-dom";
import { isNumberObject } from "util/types";
import Banner from "../../../ui/Banner/Banner";
import { formatDateToDateFormFormat } from "../../../../utilities/dateUtils";
import { getAllTags } from "../../../../services/tagServices/TagService";
import Tag from "../../../../interfaces/tag/Tag";

interface OptionType {
  value: string;
  label: string;
}

const EditRecurringTasksPage: React.FC = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean>(false);
  const [addUserToggle, setAddUserToggle] = useState<boolean>(false);
  const [triggerTaskToggle, setTriggerTaskToggle] = useState<boolean>(false);
  const [editUsers, setEditUsers] = useState<string>("");
  const [recurringTasks, setRecurringTasks] = useState<TaskGroup>();
  const [templateTask, setTemplateTask] = useState<TemplateTask>();
  const [templateTaskId, setTemplateTaskId] = useState<number>();
  const [assignee, setAssignee] = useState<string>("");
  const [addTaskToggle, setAddTaskToggle] = useState<boolean>(false);
  const [selectedTaskToAdd, setSelectedTaskToAdd] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [selectedTaskToRun, setSelectedTaskToRun] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedEndDateToRun, setSelectedEndDateToRun] = useState<string>(
    formatDateToDateFormFormat(new Date())
  );
  const [selectedStartDateToRun, setSelectedStartDateToRun] = useState<string>(
    formatDateToDateFormFormat(new Date())
  );
  const [addTemplateTaskOptions, setAddTemplateTaskOptions] = useState<
    OptionType[]
  >([]);
  const [allTemplateTasks, setAllTemplateTasks] =
    useState<TemplateTaskShortResponse[]>();
  const [lkOptions, setLkOptions] = useState<
    TaskCreationLKValues | null | undefined
  >();
  const [assigneeOptions, setAssigneeOptions] = useState<OptionType[]>([]);
  const [templateTaskActions, setTemplateTaskActions] = useState<
    TemplateTaskAction[]
  >([]);
  const [templateFiles, setTemplateFiles] = useState<TemplateRequiresFile[]>(
    []
  );
  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [tagOptions, setTagOptions] = useState<OptionType[]>([]);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);

  useEffect(() => {
    const fetchAllTags = async () => {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        const tags = await getAllTags(authToken);
        if (tags) {
          setAllTags(tags);
        }
      }
    };

    fetchAllTags();
  }, [currentUser]);

  useEffect(() => {
    if (allTags.length > 0) {
      const formatted = allTags.map((t) => ({
        value: t.id.toString(),
        label: t.name,
      }));
      setTagOptions(formatted);
    }
  }, [allTags]);

  useEffect(() => {
    const fetchTaskTemplates = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplates = await getTemplateTasks(authToken);
        if (fetchedTemplates) setRecurringTasks(fetchedTemplates);
      }
    };
    const fetchTaskTemplate = async () => {
      if (currentUser !== null && templateTaskId !== undefined) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplate = await getTemplateTask(
          templateTaskId,
          authToken
        );
        const fetchedTemplateActions = await getTemplateTaskActions(
          templateTaskId,
          authToken
        );
        const fetchedTemplateFiles = await getTemplateTaskRequiredFiles(
          templateTaskId,
          authToken
        );
        if (fetchedTemplate) setTemplateTask(fetchedTemplate);
        if (fetchedTemplateActions)
          setTemplateTaskActions(fetchedTemplateActions);
        if (fetchedTemplateFiles) setTemplateFiles(fetchedTemplateFiles);
      }
    };

    const fetchAllTaskTemplates = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedTemplates = await getAllTemplateTasks(authToken);
        if (fetchedTemplates) setAllTemplateTasks(fetchedTemplates);
      }
    };
    const fetchLkValues = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedValues: TaskCreationLKValues | null =
          await getTaskLKValues(authToken);
        setLkOptions(fetchedValues);
      }
    };

    if (lkOptions) {
      const formattedAssignees = lkOptions.users.map((user) => ({
        value: user.id.toString(),
        label: user.displayName,
      }));

      setAssigneeOptions(formattedAssignees);
    }
    if (!lkOptions) {
      fetchLkValues();
    }
    if (!allTemplateTasks) {
      fetchAllTaskTemplates();
    }
    if (!recurringTasks) {
      fetchTaskTemplates();
    }
    if (templateTaskId) {
      fetchTaskTemplate();
    }
    if (allTemplateTasks) {
      const formattedTemplateTaskOptions = allTemplateTasks.map((task) => ({
        value: task.id.toString(),
        label: task.title,
      }));

      setAddTemplateTaskOptions(formattedTemplateTaskOptions);
    }
  }, [templateTaskId, reload, allTemplateTasks]);

  useEffect(() => {
    if (templateTask) {
      // if templateTask.tags is e.g. Tag[]
      const existingTagIds = templateTask.tags?.map((tag) => tag.id) ?? [];
      setSelectedTagIds(existingTagIds);
    }
  }, [templateTask]);

  const handleTagChange = (
    selectedOptions: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    const newIds = selectedOptions.map((opt) => parseInt(opt.value));
    setSelectedTagIds(newIds);
  };
  

  const deleteTaskFile = async (fileId: number) => {
    if (currentUser !== null && fileId) {
      const authToken: string = await currentUser.getIdToken();
      await deleteTemplateTaskRequiredFile(fileId, authToken);
      setReload(!reload);
    }
  };

  const deleteTaskAction = async (actionId: number) => {
    if (currentUser !== null && actionId) {
      const authToken: string = await currentUser.getIdToken();
      await deleteTemplateTaskAction(actionId, authToken);
      setReload(!reload);
    }
  };

  const submitTaskTags = async () => {
    if (templateTask !== undefined && currentUser !== null) {
        try {
          const authToken: string = await currentUser.getIdToken();
          // Create an array of promises
          console.log('calling function')
          publishTemplateTaskTags(selectedTagIds, templateTask.id, authToken)
            .then((data: number | null) => {
              // reloadFn();
            })

          // Wait for all promises to resolve
          // Only executed if all promises resolve successfully
        } catch (error) {
          console.error("An error occurred:", error);
          // Handle any errors here (e.g., show an error message to the user)
        }
    }
  };

  const reloadFn = () => {
    setReload(!reload);
  };

  const handleAddTaskChange = (selectedOption: SingleValue<OptionType>) => {
    const taskId = selectedOption ? selectedOption.value : "";
    setSelectedTaskToAdd(taskId);
  };

  const handleRunTaskChange = (selectedOption: SingleValue<OptionType>) => {
    const taskId = selectedOption ? selectedOption.value : "";
    setSelectedTaskToRun(taskId);
  };

  const removeUser = async (id: string, groupId: number | null) => {
    if (currentUser !== null && groupId !== null && groupId !== undefined) {
      const authToken: string = await currentUser.getIdToken();
      await removeUserFromTaskGroup(id, groupId, authToken);
      setRecurringTasks(undefined);
      setReload(!reload);
    }
  };

  const addUser = async (groupId: number | null) => {
    if (
      currentUser !== null &&
      assignee !== undefined &&
      groupId !== null &&
      groupId !== undefined
    ) {
      const authToken: string = await currentUser.getIdToken();
      await addUserToTaskGroup(assignee, groupId, authToken);
      setRecurringTasks(undefined);
      setReload(!reload);
    }
  };

  const toggleNewTaskAssignment = () => {
    setTriggerTaskToggle(false);
    setAddTaskToggle(!addTaskToggle);
  };

  const toggleCustomRunTrigger = () => {
    setAddTaskToggle(false);
    setTriggerTaskToggle(!triggerTaskToggle);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedStartDateToRun(value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedEndDateToRun(value);
  };

  const submitTaskToAdd = async () => {
    setIsLoading(true);
    if (selectedTaskToAdd && currentUser) {
      const authToken = await currentUser.getIdToken();
      // right now we can default to a single group since we don't support editing groups.
      const response = await submitTaskToAddToGroup(
        authToken,
        selectedTaskToAdd
      );
      if (response) {
        setReload(reload);
      }
    }
    setIsLoading(false);
  };

  const closeSuccessMessage = () => {
    setSuccessMessage("");
  };

  const submitTaskTrigger = async () => {
    setIsLoading(true);
    await delay(5000);

    if (selectedTaskToRun && currentUser) {
      const authToken = await currentUser.getIdToken();
      // right now we can default to a single group since we don't support editing groups.
      const response = await customBatchRun(
        selectedStartDateToRun,
        selectedEndDateToRun,
        selectedTaskToRun,
        authToken
      );
      if (response) {
        if (parseInt(response) > 0) {
          setSuccessMessage(
            `${response} tasks were either created or were already created for the selected recurring task`
          );
        }
        toggleCustomRunTrigger();
        setIsLoading(false);
        // setReload(reload);
      }
    }
    setIsLoading(false);
  };

  const delay = async (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  return (
    <div className="bg-background -z-50 remove-input-txt-border">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>

      <div className="bg-white w-11/12 mx-auto rounded-3xl p-5 relative -top-64 -mt-10 max-w-[1800px]">
        {!templateTaskId && (
          <div>
            <div className="flex justify-between align-middle">
              <div className="inter-font text-text text-3xl">
                Recurring Tasks
              </div>
              <div>
                <button
                  onClick={() => navigate("/task/new/recurring")}
                  className="dark-button dark:border-text-dark m-2"
                >
                  New Recurring Task
                </button>
              </div>
            </div>
            {successMessage !== "" && (
              <div>
                <Banner
                  toggleBanner={closeSuccessMessage}
                  status="success"
                  message={successMessage}
                  dismissBanner={closeSuccessMessage}
                />
              </div>
            )}
            <div className="flex flex-col gap-4 bg-card dark:bg-card-dark p-5 rounded-lg shadow-lg">
              <div className="text-text inter-font">
                Select a Task Group to edit
              </div>
              {recurringTasks &&
                Object.entries(recurringTasks).map(([taskGroup, groupData]) => (
                  <div
                    key={taskGroup}
                    className="border border-muted-dark shadow-lg rounded-md p-2 pb-3"
                  >
                    <div className="flex justify-between">
                      <div className="relative -top-5 bg-card dark:text-text-dark dark:bg-card-dark inline text-lg font-bold">
                        {taskGroup}
                      </div>
                      <div className="flex gap-4">
                        <button
                          onClick={toggleNewTaskAssignment}
                          className="dark-button"
                        >
                          Assign New Task
                        </button>
                        <button
                          onClick={toggleCustomRunTrigger}
                          className="dark-button"
                        >
                          Custom Run
                        </button>
                      </div>
                    </div>
                    {addTaskToggle && (
                      <div className="w-1/3 bg-gray-100 flex ms-auto absolute right-10 justify-center py-5 rounded-3xl shadow-lg m-2 max-w-[350px]">
                        {allTemplateTasks && (
                          <div className="mb-4 flex flex-col w-4/5">
                            <label
                              htmlFor="requiredFiles"
                              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
                            >
                              Select task to add to group :
                            </label>
                            <div className="flex gap-3 flex-col">
                              <Select
                                options={addTemplateTaskOptions.filter(
                                  (option) =>
                                    !groupData.templateTasks.some(
                                      (task) =>
                                        task.id == parseInt(option.value)
                                    )
                                )}
                                className="w-full"
                                onChange={handleAddTaskChange}
                                value={addTemplateTaskOptions.find(
                                  (option) => option.value === selectedTaskToAdd
                                )}
                              />
                              {isLoading ? (
                                <button
                                  disabled
                                  type="button"
                                  className="py-2.5 px-5 me-2 text-sm font-medium w-full text-center  text-white bg-[#3DD598] rounded border border-gray-200 hover:bg-[#3DD598] hover:text-white focus:z-10 focus:ring-2 focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex justify-center items-center"
                                >
                                  <svg
                                    aria-hidden="true"
                                    role="status"
                                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="#3DD598"
                                    />
                                  </svg>
                                  Loading...
                                </button>
                              ) : (
                                <button
                                  onClick={submitTaskToAdd}
                                  className="bg-[#3DD598] text-white rounded w-full p-2"
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    {triggerTaskToggle && (
                      <div className="w-1/3 bg-gray-100 flex ms-auto absolute right-10 justify-center py-5 rounded-3xl shadow-lg m-2 max-w-[350px]">
                        {allTemplateTasks && (
                          <div className="mb-4 w-4/5">
                            <label
                              htmlFor="requiredFiles"
                              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
                            >
                              Select task to execute :
                            </label>
                            <div className="flex gap-3 remove-input-txt-border">
                              <Select
                                options={addTemplateTaskOptions.filter(
                                  (option) => option.value != selectedTaskToRun
                                )}
                                className="w-full"
                                onChange={handleRunTaskChange}
                                value={addTemplateTaskOptions.find(
                                  (option) => option.value === selectedTaskToAdd
                                )}
                              />
                            </div>
                            <div className="my-4">
                              <label
                                htmlFor="requiredFiles"
                                className="block text-text dark:text-text-dark text-sm font-bold mb-2"
                              >
                                Select Start Date for execution :
                              </label>
                              <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                value={selectedStartDateToRun}
                                onChange={handleStartDateChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
                              />
                            </div>
                            <div className="my-4">
                              <label
                                htmlFor="requiredFiles"
                                className="block text-text dark:text-text-dark text-sm font-bold mb-2"
                              >
                                Select End Date for execution :
                              </label>
                              <input
                                type="date"
                                id="endDate"
                                name="endDate"
                                value={selectedEndDateToRun}
                                onChange={handleEndDateChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
                              />
                            </div>
                            {isLoading ? (
                              <button
                                disabled
                                type="button"
                                className="py-2.5 px-5 me-2 text-sm font-medium w-full text-center  text-white bg-[#3DD598] rounded border border-gray-200 hover:bg-[#3DD598] hover:text-white focus:z-10 focus:ring-2 focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex justify-center items-center"
                              >
                                <svg
                                  aria-hidden="true"
                                  role="status"
                                  className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="#3DD598"
                                  />
                                </svg>
                                Loading...
                              </button>
                            ) : (
                              <button
                                disabled={isLoading}
                                onClick={
                                  !isLoading ? submitTaskTrigger : () => {}
                                }
                                className="bg-[#3DD598] text-white rounded w-full p-2"
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    <div className="text-sm text-text inter-font mb-2">
                      Assigned Tasks
                    </div>
                    <div className="flex gap-5 flex-wrap">
                      {groupData.templateTasks.map((task, index) => (
                        <div key={index}>
                          <div className="flex w-48">
                            <div className="flex w-48 flex-row gap-2 align-middle justify-between hover:text-primary bg-background rounded-lg shadow-md hover:shadow-sm p-4">
                              <div className="flex gap-2 align-middle">
                                <div className="">
                                  <FontAwesomeIcon icon={faTasks} />
                                </div>
                                <div>{task.title}</div>
                              </div>
                              <div>
                                <div
                                  onClick={() => setTemplateTaskId(task.id)}
                                  className="cursor-pointer"
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {templateTaskId && (
          <div>
            <div
              className="hover:text-muted cursor-pointer my-5 ms-10"
              onClick={() => setTemplateTaskId(undefined)}
            >
              {"< "} Back
            </div>
            <div className="text-4xl mb-5 ms-5 inter-font text-text">Edit</div>
            {templateTask && (
              <div className="">
                <div className="flex flex-col gap-4 bg-card p-5 rounded-lg shadow-lg border">
                  <div className="important">
                    Template: {templateTask?.title}
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <span className="font-bold">Title: </span>
                      {templateTask.title}
                    </div>
                    <div className="flex flex-col">
                      <span className="font-bold">Description: </span>
                      <span className="text-muted text-sm ms-5">
                        {templateTask.description}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="font-bold">Task Organization: </span>
                      {templateTask.taskOrganization.type}
                    </div>
                    <div className="flex justify-between">
                      <span className="font-bold">Task Type: </span>
                      {templateTask.taskType.type}
                    </div>
                    <div className="flex justify-between">
                      <span className="font-bold">Task Sub-Type: </span>
                      {templateTask.taskSubType.type}
                    </div>
                    <div className="flex justify-between">
                      <span className="font-bold">
                        Scheduling Definitions:{" "}
                      </span>
                      <div className="bg-background p-2">
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">
                            Distribution Pattern:
                          </span>{" "}
                          <span>{templateTask.distributionPattern}</span>
                        </div>
                        {templateTask.waiverName && (
                          <div className="flex justify-between gap-5">
                            <span className="font-bold">Waiver Type:</span>{" "}
                            <span>{templateTask.waiverName}</span>
                          </div>
                        )}
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">
                            Due Date Calculation Type:
                          </span>{" "}
                          <span>{templateTask.dueDateCalculationType}</span>
                        </div>
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">Due Date Offset:</span>{" "}
                          <span>{templateTask.dueDateOffset}</span>
                        </div>
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">Due Date Cron:</span>{" "}
                          <span>{templateTask.dueDateCron}</span>
                        </div>
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">
                            Creation Date Calculation Type:
                          </span>{" "}
                          <span>
                            {templateTask.creationDateCalculationType}
                          </span>
                        </div>
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">
                            Creation Date Offset:
                          </span>{" "}
                          <span>{templateTask.creationDateOffset}</span>
                        </div>
                        <div className="flex justify-between gap-5">
                          <span className="font-bold">Creation Date Cron:</span>{" "}
                          <span>
                            {templateTask.creationCron
                              ? templateTask.creationCron
                              : "<undefined>"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                {templateTaskActions.length > 0 && (
                  <div className="flex flex-col gap-4 bg-card p-5 rounded-lg shadow-lg my-5">
                    <div className="important">Task Actions</div>
                    {templateTaskActions.map((action, index) => (
                      <div
                        key={index}
                        className="bg-background shadow-lg rounded-lg p-4 flex gap-2 justify-between"
                      >
                        <div className="flex justify-between gap-2">
                          <span className="font-bold text-primary">
                            <FontAwesomeIcon icon={faBolt} />{" "}
                          </span>{" "}
                          <span>{action.description}</span>
                        </div>
                        <div
                          className="text-danger cursor-pointer"
                          onClick={() => deleteTaskAction(action.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <AddTaskAction taskId={templateTask.id} reload={reloadFn} />
                {templateTask && (
                  <div className="flex flex-col gap-4 bg-card p-10 rounded-lg shadow-lg my-5 border">
                    <div className="font-bold text-primary">Task Tags</div>
                    <Select
                      options={tagOptions}
                      isMulti
                      onChange={handleTagChange}
                      value={tagOptions.filter((opt) =>
                        selectedTagIds.includes(parseInt(opt.value))
                      )}
                    />
                    <button onClick={submitTaskTags} className="bg-brandSecondary text-white rounded-lg ms-auto p-2 min-w-[75px]">Add</button>
                  </div>
                )}
                {templateFiles.length > 0 && (
                  <div className="flex flex-col gap-4 bg-card p-5 rounded-lg shadow-lg my-5 border">
                    <div className="important">Task Actions</div>
                    {templateFiles.map((file, index) => (
                      <div
                        key={index}
                        className="bg-background shadow-lg rounded-lg p-4 flex justify-between gap-2"
                      >
                        <div className="flex justify-between gap-2">
                          <span className="font-bold text-primary">
                            <FontAwesomeIcon icon={faFile} />{" "}
                          </span>{" "}
                          <span>{file.templateFile?.fileName}</span>
                        </div>
                        <div
                          className="text-danger cursor-pointer"
                          onClick={() => deleteTaskFile(file.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <AddRequiredFile taskId={templateTask.id} reload={reloadFn} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditRecurringTasksPage;

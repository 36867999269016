import React, { useEffect, useState } from "react";
import TaskList from "../../../interfaces/task/TaskList";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";
import { getTasks } from "../../../services/taskServices/TaskService";
import { useAuth } from "../../../firebase/AuthProvider";
import TasksPageNew from "./TaskPageNew";
import { getTaskLKValues } from "../../../services/taskServices/TaskLKService";
import { User } from "../../../interfaces/User";
import Participant from "../../../interfaces/Participant";
import { getAllParticipants } from "../../../services/participantServices/ParticipantService";
import Status from "../../../interfaces/task/Status";
import { useLocation } from "react-router-dom";
import TaskFilters from "../../../interfaces/task/TaskFilters";

const TasksPageComponent: React.FC = () => {
  const { currentUser, currentRoles } = useAuth();
  const location = useLocation();

  const [tasks, setTasks] = useState<TaskList[] | null>([]);
  const [totalTasksCount, setTotalTasksCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25); // eventually add toggle to change this value
  const [page, setPage] = useState<number>(1);
  const [avatars, setAvatars] = useState<Avatar[] | null>([]);
  const [assignees, setAssignees] = useState<User[]>([]);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [statuses, setStatuses] = useState<Status[] | null | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Filter state (what the user has selected)
  const [filters, setFilters] = useState<TaskFilters>({
    status: [],
    type: [],
    date: [],
    assignees: [],
    participants: [],
  });

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  useEffect(() => {
    // placeholder
  }, [page]);

  useEffect(() => {
    if (currentUser !== null) {
      const fetchTasksForCurrentUser = async () => {
        setIsLoading(true);
        const authToken: string = await currentUser.getIdToken();
        let fetchedTasks;
        let fetchedTotalCount;
        if (currentRoles?.some((role) => role.name !== "SC")) {
          let response = await getTasks(
            authToken,
            undefined,
            page,
            pageSize,
            filters
          );
          fetchedTotalCount = response?.totalCount;
          fetchedTasks = response?.tasks;
        } else {
          let response = await getTasks(
            authToken,
            currentUser.uid,
            page,
            pageSize,
            filters
          );
          fetchedTotalCount = response?.totalCount;
          fetchedTasks = response?.tasks;
        }
        const sortedTasks = fetchedTasks?.sort(
          (a, b) =>
            new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
        );
        if (sortedTasks) {
          setTasks(sortedTasks);
          if (fetchedTotalCount) {
            setTotalTasksCount(fetchedTotalCount || 0);
          }
        }
        setIsLoading(false);
      };

      const fetchLkValues = async () => {
        if (currentUser !== null) {
          const authToken: string = await currentUser.getIdToken();
          await getTaskLKValues(authToken).then((data) => {
            if (data) {
              setAssignees(data?.users);
            }
            setStatuses(data?.statuses);
          });
          await getAllParticipants(authToken).then((data) => {
            if (data) setParticipants(data);
          });
        }
      };
      fetchLkValues();
      fetchTasksForCurrentUser();
    }
  }, [currentUser, currentRoles, page, filters, pageSize]);

  const changePage = (page: number) => {
    setPage(page); // Use functional update to ensure the correct value
  };

  const onFilterChange = (newFilters: TaskFilters) => {
    setFilters(newFilters);
    setPage(1); // reset to page 1 when filters change
  };

  return !assignees.length || !participants.length || !statuses ? (
    <div>Loading tasks...</div>
  ) : (
    <TasksPageNew
      assignees={assignees || []}
      tasks={tasks || []}
      participants={participants || []}
      statuses={statuses || []}
      avatars={avatars || []}
      currentPage={page}
      totalTasks={totalTasksCount}
      pageSize={pageSize}
      onPageChange={changePage}
      onFilterChange={onFilterChange}
      isLoading={isLoading}
    />
  );
};

export default TasksPageComponent;

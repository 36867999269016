// src/components/tag/TagCreationForm.tsx
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  createTag,
  getTagById,
  updateTag,
} from "../../../../services/tagServices/TagService";
import { useAuth } from "../../../../firebase/AuthProvider";
import Tag from "../../../../interfaces/tag/Tag";

interface TagCreationFormProps {
  isEdit?: boolean; // if true, this form is editing an existing tag
}

const TagCreationForm: React.FC<TagCreationFormProps> = ({
  isEdit = false,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const [tagData, setTagData] = useState<Omit<Tag, "id">>({
    name: "",
    color: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // If editing, fetch the existing tag details
  useEffect(() => {
    if (isEdit && id && currentUser) {
      (async () => {
        setLoading(true);
        const authToken = await currentUser.getIdToken();
        const existingTag = await getTagById(authToken, parseInt(id));
        setLoading(false);
        if (!existingTag) {
          setError("Tag not found or error fetching.");
          return;
        }
        // Populate the form with existing data
        setTagData({
          name: existingTag.name,
          color: existingTag.color ?? "",
        });
      })();
    }
  }, [isEdit, id, currentUser]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTagData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!currentUser) return;
    setLoading(true);
    const authToken = await currentUser.getIdToken();

    try {
      let result: Tag | null = null;
      if (isEdit && id) {
        // Update existing
        result = await updateTag(authToken, parseInt(id), tagData);
      } else {
        // Create new
        result = await createTag(authToken, tagData);
      }

      setLoading(false);

      if (!result) {
        setError("An error occurred saving the tag.");
        return;
      }
      // Successfully created/updated - navigate away or do something else
      navigate("/tags"); // your route listing tags
    } catch (err: any) {
      console.error("Error in saving tag:", err);
      setError(err.toString());
      setLoading(false);
    }
  };

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-hidden max-w-full flex-grow flex-wrap">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="relative -top-64 -mt-10 bg-white min-h-[350px] w-11/12 mx-auto p-5 inter-font text-text rounded-3xl shadow-lg border">
        <h1 className="text-2xl font-bold mb-4 text-text inter-font">
        {isEdit ? "Edit Tag" : "Create New Tag"}
      </h1>

      {error && <p className="text-red-500">{error}</p>}

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="max-w-[400px] mx-auto mt-10">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Tag Name
            </label>
            <input
              type="text"
              name="name"
              value={tagData.name}
              placeholder="Example Tag Name"
              onChange={handleChange}
              className="border rounded w-full px-3 py-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Color (Hex or any string)
            </label>
            <input
              type="text"
              name="color"
              value={tagData.color ?? ""}
              onChange={handleChange}
              className="border rounded w-full px-3 py-2"
              placeholder="#FF5733 or leave blank"
            />
          </div>
          <div className="flex justify-end">
            <button
              onClick={handleSubmit}
              className="bg-brandSecondary text-white px-4 py-2 rounded hover:bg-green-600"
            >
              {isEdit ? "Update Tag" : "Create Tag"}
            </button>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};

export default TagCreationForm;

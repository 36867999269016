import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../firebase/AuthProvider";
import Task from "../../../../interfaces/task/Task";
import { fetchLatestTasksForUser } from "../../../../services/taskServices/TaskService";
import { formatDateToWords, getTimeAgo } from "../../../../utilities/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TaskAction from "../../../../interfaces/task/taskActions/TaskAction";
import { fetchLatestActivityForUser } from "../../../../services/taskServices/TaskActionService";
import {
  faBolt,
  faBook,
  faCar,
  faCheckCircle,
  faCircleInfo,
  faCircleUser,
  faComments,
  faEnvelope,
  faFax,
  faFileUpload,
  faMagnifyingGlass,
  faPen,
  faPhone,
  faSearch,
  faTimesCircle,
  faTrashAlt,
  faUpRightFromSquare,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { getAllUsersInOrganization } from "../../../../services/userServices/UserInfoService";
import UserInfo from "../../../../interfaces/UserInfo";

const parseTaskActionDescription = (
  activity: TaskAction,
  users: UserInfo[] | null,
  navigate: (path: string) => void
): string | JSX.Element => {
  const { actionSubType, description, author } = activity;

  const renderDescription = (chat: TaskAction) => {
    if (!chat.description) {
      return null;
    }

    let parts = chat.description.split(/({user:[^}]+}|\n)/g);

    return parts.map((part, i) => {
      if (part === "\n") {
        return <br key={i} />;
      }
      const match = part.match(/{user:(.+)}/);
      if (match) {
        const userId = match[1];
        const mentionedUser = users?.find((user) => user.id === userId);

        if (mentionedUser) {
          return (
            <span
              key={i}
              className="bg-blue-100 text-blue-700 rounded p-0.5 cursor-pointer mx-0.5 text-sm px-1"
              onClick={() => navigate(`/profile/user/${mentionedUser.id}`)}
            >
              <FontAwesomeIcon icon={faCircleUser} className="mr-1" />
              {mentionedUser.displayName}
            </span>
          );
        } else {
          return `{Unknown User}`;
        }
      } else {
        return part;
      }
    });
  };

  switch (actionSubType.actionSubTypeName) {
    case "COMMENT":
      const commenter = users?.find((user) => user.id === author?.id);
      const commentText = description || "No comment provided.";
      return (
        <>
          {commenter ? (
            <span className="font-bold">{commenter.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          commented on task{" "}
          <span
            className="font-bold text-blue-500 cursor-pointer"
            onClick={() =>
              navigate(
                `/task/${commenter?.organization.name}-${activity.taskId}`
              )
            }
          >
            {commenter?.organization.name + "-" + activity.taskId}
          </span>
          : "{renderDescription(activity)}"
        </>
      );

    case "TASK COMPLETED":
      const completer = users?.find((user) => user.id === author?.id);
      return (
        <>
          {completer ? (
            <span className="font-bold">{completer.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          completed the task{" "}
          <span className="font-bold">
            {completer?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "FILE UPLOAD":
      const uploader = users?.find((user) => user.id === author?.id);
      return (
        <>
          {uploader ? (
            <span className="font-bold">{uploader.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          uploaded a file to task{" "}
          <span className="font-bold">
            {uploader?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "TASK APPROVAL":
      const approver = users?.find((user) => user.id === author?.id);
      return (
        <>
          {approver ? (
            <span className="font-bold">{approver.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          approved the task{" "}
          <span className="font-bold">
            {approver?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "TASK REJECTED":
      const rejecter = users?.find((user) => user.id === author?.id);
      return (
        <>
          {rejecter ? (
            <span className="font-bold">{rejecter.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          rejected the task{" "}
          <span className="font-bold">
            {rejecter?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "TASK SUBMISSION":
      const submitter = users?.find((user) => user.id === author?.id);
      return (
        <>
          {submitter ? (
            <span className="font-bold">{submitter.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          submitted the task{" "}
          <span
            className="font-bold text-blue-500 cursor-pointer"
            onClick={() =>
              navigate(
                `/task/${submitter?.organization.name}-${activity.taskId}`
              )
            }
          >
            {submitter?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "TASK FILE DELETED":
      const deleter = users?.find((user) => user.id === author?.id);
      return (
        <>
          {deleter ? (
            <span className="font-bold">{deleter.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          deleted a file from task{" "}
          <span className="font-bold">
            {deleter?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "Email":
      const emailSender = users?.find((user) => user.id === author?.id);
      return (
        <>
          {emailSender ? (
            <span className="font-bold">{emailSender.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          sent an email related to task{" "}
          <span className="font-bold">
            {emailSender?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "Phone Call":
      const caller = users?.find((user) => user.id === author?.id);
      return (
        <>
          {caller ? (
            <span className="font-bold">{caller.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          made a phone call related to task{" "}
          <span className="font-bold text-blue-500">
            {caller?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    default:
      return description || "No additional details available.";
  }
};

const UserProfileOverview: React.FC = ({}) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { userId } = useParams();

  const [latestTasks, setLatestTasks] = useState<Task[]>([]);
  const [latestActivity, setLatestActivity] = useState<TaskAction[]>([]);
  const [users, setUsers] = useState<UserInfo[] | null>([]);

  useEffect(() => {
    if (currentUser) {
      const fetchData = async () => {
        const authToken = await currentUser.getIdToken();
        const data = await fetchLatestTasksForUser(authToken, userId);
        if (data) {
          console.log(data);
          setLatestTasks(data);
        }
      };

      const fetchActivity = async () => {
        const authToken = await currentUser.getIdToken();
        const data = await fetchLatestActivityForUser(authToken, userId);
        if (data) {
          console.log(data);
          setLatestActivity(data);
        }
      };

      const fetchUsers = async () => {
        if (currentUser) {
          const authToken = await currentUser.getIdToken();
          const fetchedUsers = await getAllUsersInOrganization(authToken);
          setUsers(fetchedUsers);
        }
      };

      fetchData();
      fetchActivity();
      fetchUsers();
    }
  }, [currentUser, userId]);

  const navigateFunction = (path: string) => {
    navigate(path);
  };

  const getIconForActionSubtype = (actionSubtype: string) => {
    const icons: { [key: string]: any } = {
      "FILE UPLOAD": faFileUpload,
      COMMENT: faComments,
      "TASK APPROVAL": faCheckCircle,
      "TASK SUBMISSION": faMagnifyingGlass,
      "TASK COMPLETED": faCheckCircle,
      "TASK FILE DELETED": faTrashAlt,
      Email: faEnvelope,
      Fax: faFax,
      "Phone Call": faPhone,
      "Report Writing": faPen,
      "Resource Research": faSearch,
      Other: faBolt,
      Documentation: faBook,
      REJECTED: faTimesCircle,
      Meeting: faUsers,
      Travel: faCar,
      Logging: faPen,
    };

    return icons[actionSubtype] || faCircleInfo; // Default icon if subtype not found
  };

  console.log(latestActivity);

  return (
    <div>
      <div className="latest-tasks inter-font bg-gray-200 p-2 px-5 rounded-md mt-5">
        <div className="flex justify-between align-middle items-center">
          <div className="font-bold">Latest Tasks</div>
          <div
            className="text-blue-500 cursor-pointer text-xs"
            onClick={() => navigate(`/profile/user/${userId}/tasks`)}
          >
            Show all
          </div>
        </div>
        <div className="task-list-overview flex flex-col p-2 gap-2">
          {latestTasks.length > 0 &&
            latestTasks.map((task, index) => (
              <div
                key={index}
                className="task-list-item bg-white p-2 rounded-sm shadow-md flex justify-between items-center align-middle inter-font font-normal text-sm hover:shadow-none cursor-pointer"
                onClick={() =>
                  navigate(`/task/${task.organization.name}-${task.id}`)
                }
              >
                <div>
                  <div
                    className={`${
                      task.completionTime && "line-through text-inactive"
                    }`}
                  >
                    {task.title}
                  </div>
                  {/* <div>{task.completionTime ? formatDateToWords(task.completionTime) : ""}</div> */}
                </div>
                <div
                  className={`text-xs p-1 bg-gray-200 rounded-sm ${
                    new Date(task.endDate).getTime() < new Date().getTime() &&
                    !task.completionTime &&
                    "text-red-500 bg-red-100"
                  } ${task.completionTime && "text-green-500 bg-green-100"}`}
                >
                  {formatDateToWords(task.endDate)}
                </div>
              </div>
            ))}
          {latestTasks.length === 0 && (
            <div className="text-inactive text-xs text-center mt-5">
              There are currenly no tasks to display here.
            </div>
          )}
        </div>
      </div>
      <div className="latest-activity inter-font p-2 mt-5 px-5 rounded-md">
        <div className="flex gap-3 align-middle items-center">
          <div className="font-bold">Latest Activity</div>
          <div
            className="text-blue-500 cursor-pointer text-sm"
            onClick={() => navigate(`/profile/user/${userId}/activities`)}
          >
            <FontAwesomeIcon icon={faUpRightFromSquare} />
          </div>
        </div>
        <div className="activity-list border-l flex flex-col px-5 py-2 mx-2">
          {latestActivity.length > 0 &&
            latestActivity.map((activity, index) => (
              <div
                className="activity-list-item flex items-center align-middle gap-2 -ms-12 "
                key={index}
              >
                <div className="flex items-center align-middle">
                  <div>
                    <div className="m-1 z-40 bg-white w-12 h-12 flex items-center justify-center">
                      <div className="activity-icon text-blue-500 rounded-full h-7 w-7 flex justify-center items-center bg-blue-100">
                        <FontAwesomeIcon
                          icon={getIconForActionSubtype(
                            activity.actionSubType.actionSubTypeName
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="activity-description text-muted text-xs">
                      {parseTaskActionDescription(
                        activity,
                        users,
                        navigateFunction
                      )}
                    </div>
                    <div className="text-gray-400 text-2xs">
                      {getTimeAgo(activity.createDate)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {latestActivity.length === 0 && (
          <div className="text-inactive text-xs text-center mt-5">
            There is currenly no activity to display here.
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfileOverview;

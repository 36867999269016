// Home.tsx
import React from "react";
import { Routes, Route, useParams } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import Drive from "../drive/Drive/Drive";
import Calendar from "../calendar/Calendar";
import Reports from "../reports/Reports";
import Profile from "../profile/Profile";
import TaskPage from "../taskPage/TaskPage";
import NewFileRequirementForm from "../taskPage/taskAttachments/NewFileRequirementForm";
import RecurringTaskCreation from "../task/taskCreation/RecurringTaskCreation";
import TaskCreationSelector from "../task/taskCreation/TaskCreationSelector";
import EditRecurringTasksPage from "../task/taskEdit/EditRecurringTasksPage";
import EditWorkflows from "../workflow/EditWorkflows";
import EditParticipantPage from "../participants/EditParticipantPage";
import FileViewPage from "../fileViewer/FileViewPage";
import CreateParticipantPage from "../participants/CreateParticipantPage";
import TeamMemberDashboard from "../dashboard/TeamMemberDashboard";
import CreateTaskActivityComponent from "../taskActivity/CreateTaskActivityComponent";
import EditTemplateInterimTasks from "../task/taskEdit/EditTemplateInterimTasks";
import AddTemplateInterimTask from "../task/taskEdit/AddTemplateInterimTask";
import TaskCreationPage from "../task/taskCreation/TaskCreationPage";
import EditTemplateFiles from "../templateFiles/EditTemplateFiles";
import AddTemplateFile from "../templateFiles/AddTemplateFile";
import CreateNewTaskActivityType from "../taskActivity/CreateNewTaskActivityType";
import CreateNewTaskActivityPage from "../taskActivity/CreateNewTaskActivityPage";
import EmployeeDrive from "../drive/Drive/EmployeeDrive";
import DynamicForm from "../formFillComponents/DynamicForm";
import { FormConfig } from "../../../interfaces/dynamicForms/FormInterfaces";
import TasksPageComponent from "../TasksPage/TasksPageComponent";
import BumpParticipantForm from "../participants/BumpParticipantForm";
import UserFileViewPage from "../fileViewer/UserFileViewPage";
import GeneratedSuggestionComponent from "../generatedSuggestions/GeneratedSuggestionComponent";
import SuggestionRulesComponent from "../generatedSuggestions/SuggestionRulesComponent";
import ParticipantPage from "../participants/ParticipantPage";
import TagList from "../task/tagCreation/TagList";
import TagCreationForm from "../task/tagCreation/TagCreationForm";
import DriveParticipant from "../drive/Drive/caseFileDrive/DriveParticipant";
import DriveYear from "../drive/Drive/caseFileDrive/DriveYear";
import CaseFileDrive from "../drive/Drive/caseFileDrive/CaseFileDrive";
import { DriveProvider } from "../../../contexts/DriveContext";
import PersonalDrive from "../drive/Drive/personalDrive/PersonalDrive";
import PersonalFolder from "../drive/Drive/personalDrive/PersonalFolder";
import UserProfile from "../userComponents/UserProfile";
import UserProfileOverview from "../userComponents/userProfileComponents/UserProfileOverview";
import UserProfileTasks from "../userComponents/userProfileComponents/UserProfileTasks";
import UserProfileDocuments from "../userComponents/userProfileComponents/UserProfileDocuments";
import UserProfileFiles from "../userComponents/userProfileComponents/UserProfileFiles";
import UserProfileActivities from "../userComponents/userProfileComponents/UserProfileActivities";

const Home: React.FC = () => {
  const config: FormConfig = {
    FormName: "SCD Log",
    Waiver: "OCDD-NOW",
    AuthorizedRoles: ["Admin", "User"],
    Mappings: [
      {
        AnswerId: "1",
        PDFField: "Field1",
      },
      {
        AnswerId: "2",
        PDFField: "Field2",
      },
    ],
    Answers: [
      {
        Id: "1",
        DerivedFrom: ["Q1", "Q2"],
        Logic:
          "formState['Q1'] === 'Yes' && formState['Q2'] === 'Yes' ? '25' : ''",
      },
      {
        Id: "2",
        DerivedFrom: ["Q3"],
        Logic: "formState['Q3']",
      },
    ],
    Steps: [
      {
        StepName: "Personal Information",
        StepId: "step1",
        Questions: [
          {
            Id: "Q1",
            Text: "Have you ever had an SCD?",
            Type: "Boolean",
          },
          {
            Id: "Q2",
            Text: "When was your last SCD?",
            Type: "Date",
            Conditional: "formState['Q1'] === 'Yes'",
          },
        ],
      },
      {
        StepName: "Health Status",
        StepId: "step2",
        Conditional: "formState['Q1'] === 'Yes'", // Skip this step if Q1 is "No"
        Questions: [
          {
            Id: "Q3",
            Text: "What is your current health status?",
            Type: "Text",
            Placeholder: "Tell us a little about your health status...",
          },
          {
            Id: "Q4",
            Text: "Select your preferred treatment method.",
            Type: "SingleSelect",
          },
          {
            Id: "Q5",
            Text: "Select the symptoms you are experiencing.",
            Type: "MultiSelect",
          },
        ],
      },
      {
        StepName: "Pain Assessment",
        StepId: "step3",
        Questions: [
          {
            Id: "Q6",
            Text: "What time did your symptoms start?",
            Type: "Time",
          },
          {
            Id: "Q7",
            Text: "Rate your pain level (1-10).",
            Type: "Number",
          },
        ],
      },
    ],
    Options: [
      {
        QuestionId: "Q1",
        Options: [
          { value: "Yes", label: "Yes" },
          { value: "No", label: "No" },
        ],
      },
      {
        QuestionId: "Q4",
        Options: [
          { value: "medication", label: "Medication" },
          { value: "therapy", label: "Therapy" },
          { value: "surgery", label: "Surgery" },
        ],
      },
      {
        QuestionId: "Q5",
        Options: [
          { value: "pain", label: "Pain" },
          { value: "fever", label: "Fever" },
          { value: "nausea", label: "Nausea" },
        ],
      },
    ],
    Validations: [
      {
        QuestionId: "Q2",
        ValidationType: "required",
        ErrorMessage: "Date is required.",
      },
      {
        QuestionId: "Q3",
        ValidationType: "minLength",
        Value: 5,
        ErrorMessage: "Health status must be at least 5 characters long.",
      },
      {
        QuestionId: "Q4",
        ValidationType: "required",
        ErrorMessage: "You must select a treatment method.",
      },
      {
        QuestionId: "Q5",
        ValidationType: "minSelection",
        Value: 1,
        ErrorMessage: "You must select at least one symptom.",
      },
      {
        QuestionId: "Q7",
        ValidationType: "range",
        MinValue: 1,
        MaxValue: 10,
        ErrorMessage: "Pain level must be between 1 and 10.",
      },
    ],
  };

  return (
    <div className="relative flex-col w-full dark:bg-black  bg-black max-h-svh">
      <div className="fixed bottom-5 right-5 z-50">
        <GeneratedSuggestionComponent />
      </div>
      <div className="relative zindex-1 -mt-2 sm:min-w-[640px]">
        <Routes>
          <Route path="/home" element={<Dashboard />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/dashboard/*" element={<TeamMemberDashboard />}></Route>
          <Route
            path="/drive"
            element={
              <DriveProvider>
                <Drive />
              </DriveProvider>
            }
          >
            {/* CaseFileDrive with and without employeeId */}
            <Route
              path="caseFileDrive/employee/:employeeId"
              element={<CaseFileDrive />}
            />
            <Route path="caseFileDrive" element={<CaseFileDrive />} />
            {/* DriveParticipant with employeeId */}
            <Route
              path="caseFileDrive/employee/:employeeId/participant/:participantId"
              element={<DriveParticipant />}
            />
            <Route
              path="caseFileDrive/participant/:participantId"
              element={<DriveParticipant />}
            />
            {/* DriveYear with employeeId */}
            <Route
              path="caseFileDrive/employee/:employeeId/participant/:participantId/:year"
              element={<DriveYear />}
            />
            <Route
              path="caseFileDrive/participant/:participantId/:year"
              element={<DriveYear />}
            />
            <Route path="personal" element={<PersonalDrive />}>
              <Route index element={<PersonalDrive />} />
              <Route path=":folderId" element={<PersonalFolder />} />
            </Route>
            {/*
            Later, you can add a PersonalDrive route:
            <Route path="personalDrive" element={<PersonalDrive />} />
            <Route path="personalDrive/*" ... etc.
          */}
          </Route>
          {/* <Route path="/drive/*" element={<Drive />}>
            <Route path=":participant" element={<Drive />} />
            <Route path=":participant/:year" element={<Drive />} />
            <Route path=":participant/:year/:folder" element={<Drive />} />
          </Route>
          <Route
            path="/CaseFileDrive/:participantId/:year"
            element={<DriveYear />}
          />
          <Route
            path="/CaseFileDrive/:participantId"
            element={<DriveParticipant />}
          />
          <Route path="/CaseFileDrive/*" element={<CaseFileDrive />}></Route>
          <Route path="/employeeDrive/:userId/*" element={<EmployeeDrive />}>
            <Route path=":participant" element={<EmployeeDrive />} />
            <Route path=":participant/:year" element={<EmployeeDrive />} />
            <Route
              path=":participant/:year/:folder"
              element={<EmployeeDrive />}
            />
          </Route> */}
          <Route
            path="/edit/suggestionRules/*"
            element={<SuggestionRulesComponent />}
          ></Route>
          <Route
            path="/edit/participant/:id/*"
            element={<ParticipantPage />}
          ></Route>
          <Route
            path="/edit/task/recurring/*"
            element={<EditRecurringTasksPage />}
          ></Route>
          <Route path="/edit/workflows/*" element={<EditWorkflows />}></Route>
          <Route
            path="/edit/participants/new"
            element={<CreateParticipantPage />}
          ></Route>
          <Route
            path="/edit/participants/*"
            element={<EditParticipantPage />}
          ></Route>
          <Route
            path="/edit/interims/*"
            element={<EditTemplateInterimTasks />}
          ></Route>
          <Route
            path="/edit/templateFiles/*"
            element={<EditTemplateFiles />}
          ></Route>
          <Route
            path="/task/new/interim/template/*"
            element={<AddTemplateInterimTask />}
          ></Route>
          <Route path="/reports/*" element={<Reports />}></Route>
          <Route path="/profile/user/:userId" element={<UserProfile />}>
            <Route index element={<UserProfileOverview />} />
            <Route path="tasks" element={<UserProfileTasks />} />
            <Route path="activities" element={<UserProfileActivities />} />
            <Route path="documents" element={<UserProfileDocuments />} />
            <Route path="files" element={<UserProfileFiles />} />
          </Route>{" "}
          <Route path="/profile/*" element={<Profile />}></Route>
          <Route
            path="/task/new/recurring"
            element={<RecurringTaskCreation />}
          ></Route>
          <Route
            path="/file/new/template"
            element={<AddTemplateFile />}
          ></Route>
          <Route
            path="/task/new/activity/template"
            element={<CreateNewTaskActivityType />}
          ></Route>
          <Route
            path="/task/edit/activity/template"
            element={<CreateNewTaskActivityPage />}
          ></Route>
          <Route
            path="/task/new/activity/*"
            element={<CreateTaskActivityComponent />}
          ></Route>
          <Route
            path="/task/new/interim"
            element={<TaskCreationPage />}
          ></Route>
          <Route path="/task/new/*" element={<TaskCreationSelector />}></Route>
          <Route
            path="/task/:taskId/addRequiredFile"
            element={<NewFileRequirementForm />}
          ></Route>
          <Route path="/task/:taskId" element={<TaskPage />}></Route>
          <Route path="/task/*" element={<TaskPage />}></Route>
          <Route path="/view/file/:fileId" element={<FileViewPage />}></Route>
          <Route
            path="/view/user/file/:fileId"
            element={<UserFileViewPage />}
          ></Route>
          <Route
            path="/create/form"
            element={<DynamicForm config={config} />}
          ></Route>
          <Route
            path="/edit/participants/bump/:participantId"
            element={<BumpParticipantForm />}
          ></Route>
          <Route path="/tags" element={<TagList />} />
          <Route
            path="/tags/new"
            element={<TagCreationForm isEdit={false} />}
          />
          <Route
            path="/tags/edit/:id"
            element={<TagCreationForm isEdit={true} />}
          />
          <Route
            path="/calendar"
            element={
              <Calendar
                events={[
                  {
                    id: "1",
                    title: "title",
                    date: new Date(),
                    elapsedTimeInMinutes: 30,
                    color: "red",
                  },
                  {
                    id: "2",
                    title: "title",
                    date: new Date(),
                    elapsedTimeInMinutes: 30,
                    color: "red",
                  },
                  {
                    id: "3",
                    title: "title",
                    date: new Date(),
                    elapsedTimeInMinutes: 30,
                    color: "red",
                  },
                  {
                    id: "4",
                    title: "title",
                    date: new Date(),
                    elapsedTimeInMinutes: 30,
                    color: "red",
                  },
                  {
                    id: "5",
                    title: "title",
                    date: new Date(),
                    elapsedTimeInMinutes: 30,
                    color: "red",
                  },
                ]}
              />
            }
          />
          {/* <Route path="/tasks/*" element={<TasksPage />} /> */}
          <Route path="/tasks/*" element={<TasksPageComponent />} />
          {/* <Route path="/calendar" element={<Calendar/>}></Route> */}
        </Routes>
      </div>
    </div>
  );
};

export default Home;

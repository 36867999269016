import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import NewParticipant from "../../../interfaces/participant/NewParticipant";
import {
  getWaivers,
  postParticipant,
} from "../../../services/participantServices/ParticipantService";
import { getAllUsersInOrganization } from "../../../services/userServices/UserInfoService";
import UserInfo from "../../../interfaces/UserInfo";
import Waiver from "../../../interfaces/participant/Waiver";
import Select, { SingleValue } from "react-select";
import Banner from "../../ui/Banner/Banner";
import { useNavigate } from "react-router-dom";
import { formatDateToDateFormFormat } from "../../../utilities/dateUtils";

interface OptionType {
  value: string;
  label: string;
}

const CreateParticipantPage: React.FC = () => {
  // Eventually we are going to want to set a gloabl region and get that information
  // to POST new participants to
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [newParticipant, setNewParticipant] = useState<NewParticipant>({
    firstName: "",
    lastName: "",
    caseworker: "",
    waiverId: "",
    linkageDate: null,
    cpocDate: null,
    cpocEndDate: null,
    certificationDate: null,
    initial: true,
  });

  const [employees, setEmployees] = useState<UserInfo[] | null>([]);
  const [waivers, setWaivers] = useState<Waiver[] | null>([]);

  const [employeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);
  const [waiverOptions, setWaiverOptions] = useState<OptionType[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const fetchInfo = async () => {
      if (currentUser) {
        const authToken: string = await currentUser.getIdToken();
        const waiversFetched = await getWaivers(authToken);
        const employeesFetched = await getAllUsersInOrganization(authToken);

        if (waiversFetched) {
          setWaivers(waiversFetched);
        }
        if (employeesFetched) {
          setEmployees(employeesFetched);
        }
      }
    };

    fetchInfo();
  }, [currentUser]); // Only re-fetch when currentUser changes

  useEffect(() => {
    if (waivers && waivers.length > 0) {
      const formattedWaivers = waivers.map((waiver) => ({
        value: waiver.id.toString(),
        label: waiver.waiverName,
      }));
      setWaiverOptions(formattedWaivers);
    }

    if (employees && employees.length > 0) {
      const formattedEmployees = employees.map((employee) => ({
        value: employee.id.toString(),
        label: employee.firstName + " " + employee.lastName, // Added space between names
      }));
      setEmployeeOptions(formattedEmployees);
    }
  }, [employees, waivers]); // React only when employees or waivers state changes

  const handleWaiverChange = (selectedOption: SingleValue<OptionType>) => {
    const waiverId = selectedOption ? selectedOption.value : "";
    setNewParticipant({ ...newParticipant, waiverId });
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewParticipant({ ...newParticipant, [name]: value });
    // You can perform additional actions here if needed
  };

  const handleAssigneeChange = (selectedOption: SingleValue<OptionType>) => {
    const caseworker = selectedOption ? selectedOption.value : "";
    setNewParticipant({ ...newParticipant, caseworker });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setNewParticipant({ ...newParticipant, [name]: value });
  };

  const handleLinkageTypeChange = () => {
    const initial = !newParticipant.initial;
    setNewParticipant({ ...newParticipant, initial });
  };

  const validateFields = () => {
    if (newParticipant.firstName.trim() === "") {
      setErrorMessage("The Participant First Name is a required field.");
      return false;
    } else if (newParticipant.lastName.trim() === "") {
      setErrorMessage("The Participant Last Name is a required field.");
      return false;
    } else if (newParticipant.waiverId === "") {
      setErrorMessage("The Participant Waiver Type is a required field.");
      return false;
    } else if (newParticipant.caseworker === "") {
      setErrorMessage(
        "The Participant Caseworker assignment is a required field."
      );
      return false;
    } else {
      return true;
    }
  };

  const submit = async () => {
    console.log(newParticipant);

    setIsLoading(true);
    if (validateFields() && currentUser) {
      console.log("Submitting", newParticipant);
      const authToken = await currentUser.getIdToken();
      await postParticipant(newParticipant, authToken);
      setErrorMessage("");
      navigate("/edit/participants");
    } else {
      console.error("Unable to submit creation");
    }
    setIsLoading(false);
  };

  const dismissBanner = () => {
    setErrorMessage("");
  };

  return (
    <div className="bg-[#F7F9FC] remove-input-txt-border">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="-mt-10 max-w-screen-sm mx-auto text-text dark:text-text-dark p-5 relative w-11/12 rounded-3xl -top-64 bg-white">
        <div>
          <div className="text-text inter-font text-4xl text-center">New Participant</div>
          <div className="text-muted text-sm mt-5">
            Congratulations! Every new participant is a new person we can help
            together, this is definitely a cause for celebration! &#129395;
          </div>
        </div>
        <div className="max-w-screen-sm mx-auto">
          {errorMessage !== "" && (
            <Banner
              status="error"
              message={errorMessage}
              toggleBanner={dismissBanner}
              dismissBanner={dismissBanner}
            />
          )}
        </div>
        {/* Form information here */}
        <div className="mt-10 max-w-screen-sm mx-auto bg-card dark:bg-card-dark text-text dark:text-text-dark p-5 rounded-lg shadow-lg">
          <div className="mb-5">
            <div className="font-bold">Participant First Name</div>
            <div>
              <input
                name="firstName"
                onChange={handleChange}
                placeholder="First Name"
                className="dark:bg-card-dark w-full border rounded-sm focus:border-primary focus:outline-primary p-2 focus:ring-primary"
              ></input>
            </div>
          </div>
          <div className="my-5">
            <div className="font-bold">Participant Last Name</div>
            <div>
              <input
                name="lastName"
                onChange={handleChange}
                placeholder="Last Name"
                className="dark:bg-card-dark w-full border rounded-sm focus:border-primary focus:outline-primary p-2 focus:ring-primary"
              ></input>
            </div>
          </div>
          <div className="my-5">
            <div className="font-bold">Participant Waiver</div>
            <div>
              <Select
                options={waiverOptions}
                value={waiverOptions.find(
                  (option) => option.value === newParticipant.waiverId
                )}
                onChange={handleWaiverChange}
                className="dark:text-text"
              />{" "}
            </div>
          </div>
          <div className="my-5">
            <div className="font-bold">Caseworker</div>
            <div>
              <Select
                options={employeeOptions}
                value={employeeOptions.find(
                  (option) => option.value === newParticipant.caseworker
                )}
                onChange={handleAssigneeChange}
                className="dark:text-text"
              />{" "}
            </div>
          </div>
          <div className="my-5">
            <div className="font-bold">
              Linkage Date{" "}
              <span className="text-muted font-normal">(if available)</span>
            </div>
            <div>
              <input
                type="date"
                name="linkageDate"
                className="dark:bg-card-dark w-full border rounded-sm focus:border-primary focus:outline-primary p-2 focus:ring-primary"
                max="9999-12-31"
                onChange={handleDateChange}
              ></input>
            </div>
          </div>
          <div className="my-5">
            <div className="font-bold">
              Certification Date{" "}
              <span className="text-muted font-normal">(if available)</span>
            </div>
            <div>
              <input
                type="date"
                name="certificationDate"
                className="dark:bg-card-dark w-full border rounded-sm focus:border-primary focus:outline-primary p-2 focus:ring-primary"
                max="9999-12-31"
                onChange={handleDateChange}
              ></input>
            </div>
          </div>
          <div className="my-5">
            <div className="font-bold">
              CPOC Start Date{" "}
              <span className="text-muted font-normal">(if available)</span>
            </div>
            <div>
              <input
                type="date"
                name="cpocDate"
                className="dark:bg-card-dark w-full border rounded-sm focus:border-primary focus:outline-primary p-2 focus:ring-primary"
                max="9999-12-31"
                onChange={handleDateChange}
              ></input>
            </div>
          </div>
          <div className="my-5">
            <div className="font-bold">
              CPOC End Date{" "}
              <span className="text-muted font-normal">(if available)</span>
            </div>
            <div>
              <input
                type="date"
                name="cpocEndDate"
                className="dark:bg-card-dark w-full border rounded-sm focus:border-primary focus:outline-primary p-2 focus:ring-primary"
                max="9999-12-31"
                onChange={handleDateChange}
              ></input>
            </div>
          </div>
          <div className="my-5 flex gap-2 align-middle items-center">
            <div>
              <input
                type="checkbox"
                checked={newParticipant.initial}
                className="dark:bg-card-dark w-full border rounded-sm focus:border-primary focus:outline-primary p-2 focus:ring-primary"
                onChange={handleLinkageTypeChange}
              ></input>
            </div>
            <div className="font-bold">Initial Linkage</div>
          </div>
          <div className="flex justify-end">
            {isLoading ? (
              <button disabled className="dark-button">
                Loading...
              </button>
            ) : (
              <button onClick={submit} className="dark-button">
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateParticipantPage;

import React from "react";
import TaskList from "../../../interfaces/task/TaskList";
import { useNavigate } from "react-router-dom";
import {
  calculateDateProximity,
  formatDateToMMDDYYYY,
} from "../../../utilities/dateUtils";
import Avatar from "../../../interfaces/avatar/Avatar";

interface TasksPageTable {
  tasks: TaskList[];
  avatars: Avatar[];
  displayTaskCount: boolean;
  page: number; 
  pageSize: number;
  totalCount: number;
  isLoading: boolean;
}

const TasksPageTable: React.FC<TasksPageTable> = ({
  tasks,
  avatars,
  displayTaskCount = false,
  page, 
  pageSize, 
  totalCount, 
  isLoading
}) => {
  const navigate = useNavigate();

  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  return (
    <div>
      <div className="text-muted mx-auto text-end pe-8 pb-3 pt-0 text-sm">
        {"Page: " + page} | {`Showing ${pageSize}`} | {totalCount + " total tasks"}
      </div>
      <div className="relative bg-card dark:bg-card-dark min-w-[1000px] overflow-x-auto shadow-lg mb-5 dark:text-text-dark rounded-3xl inter-font mx-5 text-[13px]">
        {isLoading && (
          <div className="absolute w-full h-full flex justify-center align-middle">
            <div className="absolute w-full h-full bg-gray-500 opacity-40 z-10"></div>
            <div className="bg-white mt-[200px] h-[200px] w-[200px] flex justify-center items-center align-middle opacity-100 rounded-xl z-20 shadow-lg">
              <div className="flex text-[#3DD598] p-2 rounded items-center align-middle justify-center gap-2 hover:rotate-icon select-none text-lg">
                <svg
                  aria-hidden="true"
                  role="status"
                  className="inline w-7 h-7 text-gray-200 animate-spin dark:text-gray-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="#3DD598"
                  />
                </svg>
                Loading...
              </div>{" "}
            </div>          </div>
        )}
        <div className="font-bold headers columns-8 px-5 py-4 border-b">
          <div className="truncate max-w-[145px] min-w-[122px]">Title</div>
          <div className="truncate max-w-[145px] min-w-[122px]">Attachment</div>
          <div className="truncate max-w-[145px] min-w-[122px]">
            Participant
          </div>
          <div className="truncate max-w-[145px] min-w-[122px]">Type</div>
          <div className="truncate max-w-[145px] min-w-[122px]">Assignee</div>
          <div className="truncate max-w-[145px] min-w-[122px]">Due Date</div>
          <div className="truncate max-w-[145px] min-w-[122px]">Proximity</div>
          <div className="truncate max-w-[145px] min-w-[122px]">Status</div>
        </div>
        {tasks?.map((task, index) => (
          <div
            onClick={() => navigate(`/task/BLOSSOM-${task.id}`)}
            key={index}
            className={`data columns-8 truncate p-3 px-5 hover:shadow-lg cursor-pointer hover:bg-background dark:hover:bg-background-dark border-t border-b ${
              index % 2 === 1 ? "bg-gray-100 dark:bg-gray-800" : ""
            }`}
          >
            <div className="truncate max-w-[145px] min-w-[122px]">
              {task.title}
            </div>
            <div className="truncate max-w-[145px] min-w-[122px] ms-8">
              <input
                type="checkbox"
                disabled={true}
                checked={task.hasAttachments}
                className="checked:bg-[#3DD598] checked:disabled:hover:bg-[#3DD598] checked:disabled:hover bg-gray-400 border-none rounded"
              />
            </div>
            <div className="truncate max-w-[145px] min-w-[122px] ">
              {task.participant?.firstName
                ? task.participant.firstName[0] +
                  ". " +
                  task.participant.lastName
                : "N/A"}
            </div>
            <div className="truncate max-w-[145px] min-w-[122px] ">
              {task.subType.type
                ? task.subType.type
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ")
                : "N/A"}
            </div>
            <div className="truncate max-w-[145px] min-w-[122px] flex flex-nowrap overflow-hidden ">
              {task.assignees
                .filter((a) => !a.isReporter) // Exclude reporters
                .map((user, index) => {
                  const avatarUrl = findAvatarById(user.user.id);
                  return (
                    <div
                      className="w-7 h-7 font-bold flex items-center justify-center rounded-full bg-gray-300 dark:text-text"
                      key={index}
                    >
                      {avatarUrl ? (
                        <img
                          src={avatarUrl}
                          alt={`${user.user.firstName} ${user.user.lastName}`}
                          className="w-full h-full rounded-full object-cover"
                        />
                      ) : (
                        <div className="text-sm">
                          {user.user.firstName[0] + user.user.lastName[0]}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
            <div className="truncate max-w-[145px] min-w-[122px] ">
              {formatDateToMMDDYYYY(task.endDate)}
            </div>
            {calculateDateProximity(task.endDate) === "Overdue" &&
            task.status.name !== 'COMPLETED' && (
              <div className="truncate max-w-[145px] min-w-[122px] font-bold text-danger">
                {calculateDateProximity(task.endDate)}
              </div>
            )} 
            {task.status.name !== 'COMPLETED' && calculateDateProximity(task.endDate) !== "Overdue" && (
              <div className="truncate max-w-[145px] min-w-[122px] ">
                {calculateDateProximity(task.endDate)}
              </div>
            )}
            {task.status.name === 'COMPLETED' && (
              <div className="truncate max-w-[145px] min-w-[122px] ps-5">
                --
              </div>
            )}
            <div className="truncate max-w-[145px] min-w-[122px] flex gap-1">
              <div>
                {task.status.name
                  .toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")}
              </div>
              {task.assigneeRoleName !== undefined &&
                task.status.name === "IN REVIEW" && (
                  <div className="font-bold">
                    {task.assigneeRoleName === "SC" && "(SC)"}
                    {task.assigneeRoleName === "SUPERVISOR" && "(SUP)"}
                    {task.assigneeRoleName === "PROGRAM MANAGER" && "(PM)"}
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TasksPageTable;

import React from "react";

interface StorageCircleGraphProps {
  freeStorage: number;  // e.g. in Bytes
  usedStorage: number;  // e.g. in Bytes
}

const StorageCircleGraph: React.FC<StorageCircleGraphProps> = ({
  freeStorage,
  usedStorage,
}) => {
  const total = freeStorage + usedStorage;
  const usedPercent = total === 0 ? 0 : (usedStorage / total) * 100;

  // We’ll use a circle with circumference = 2πr.
  // For r=45, circumference is ~282.743. We can just use 283 as a close integer.
  const circumference = 283;

  // How much of the circle will be “unfilled” = the remaining stroke
  // strokeDashoffset is how we hide part of the stroke. 
  // Example: if usedPercent=75, dashOffset = 283 - (75% of 283) = 283 - 212 = 71
  const dashOffset = circumference - (usedPercent / 100) * circumference;

  return (
    <div style={styles.wrapper}>
      <svg style={styles.svg} viewBox="0 0 100 100">
        {/* Background circle (gray track) */}
        <circle
          cx="50"
          cy="50"
          r="45"
          stroke="#ccc"           // track color
          fill="transparent"
          strokeWidth="10"
        />
        {/* Foreground circle (progress) */}
        <circle
          cx="50"
          cy="50"
          r="45"
          stroke="#7d7d7d"        // used portion color
          fill="transparent"
          strokeWidth="10"
          // The magic:
          strokeDasharray={circumference}
          strokeDashoffset={dashOffset}
          // By default, the circle starts at the right, so rotate -90° to start at the top
          transform="rotate(-90 50 50)"
          strokeLinecap="round"
        />
      </svg>

      {/* Centered label (could show used / total, or a percentage) */}
      <div style={styles.label}>
        <div style={{ fontSize: "16px", fontWeight: "bold" }}>
          {usedPercent.toFixed(0)}%
        </div>
        <div style={{ fontSize: "12px" }}>
          Used
        </div>
        {/* Or show something like: `${usedStorage} GB / ${freeStorage + usedStorage} GB` */}
      </div>
    </div>
  );
};

// Some minimal inline styles for demo
const styles: { [key: string]: React.CSSProperties } = {
  wrapper: {
    position: "relative",
    width: "150px",
    height: "150px",
  },
  svg: {
    transform: "rotate(0deg)", // not strictly necessary
    width: "100%",
    height: "100%",
  },
  label: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
};

export default StorageCircleGraph;

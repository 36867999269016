import { faClipboardUser, faIdCard, faList, faMagnifyingGlass, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { getTasks } from "../../../services/taskServices/TaskService"; // Adjust the import as necessary
import { useAuth } from "../../../firebase/AuthProvider";
import TaskList from "../../../interfaces/task/TaskList";
import { useNavigate, useLocation } from "react-router-dom";

const TaskSearch: React.FC = () => {
  const { currentUser, currentOrganization } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const searchRef = useRef<HTMLDivElement>(null);

  const [toggleSearch, setToggleSearch] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [tasks, setTasks] = useState<TaskList[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<TaskList[]>([]);

  useEffect(() => {
    if (currentUser) {
      const fetchTasks = async () => {
        const authToken = await currentUser.getIdToken();
        const fetchedTasks = await getTasks(authToken);
        if (fetchedTasks) {
          setTasks(fetchedTasks.tasks);
        }
      };

      fetchTasks();
    }
  }, [currentUser]);

  useEffect(() => {
    if (searchTerm) {
      const results = tasks
        .filter(
          (task) =>
            task.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            task.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
            task.id.toString().includes(searchTerm) || 
            task.participant?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) || 
            task.participant?.lastName.toLowerCase().includes(searchTerm.toLowerCase()) 
        )
        .slice(0, 5); // Limit to 5 suggestions
      setFilteredTasks(results);
    } else {
      setFilteredTasks([]);
    }
  }, [searchTerm, tasks]);

  const toTask = (id: string) => {
    setToggleSearch(false);
    setSearchTerm("");
    navigate(`/task/${currentOrganization?.name}-${id}`);
  };

  // Unfocus and close search on location change
  useEffect(() => {
    setToggleSearch(false);
  }, [location]);

  // Unfocus and close search on click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setToggleSearch(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative flex flex-col items-center" ref={searchRef}>
      <div className="flex justify-center items-center align-middle gap-2">
        <input
          className={`relative w-[150px] h-1/3 text-[10px] rounded border bg-background-dark transition delay-75 ${
            toggleSearch ? "opacity-100" : "opacity-0"
          } focus:border-[#3dd598]`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search tasks"
        />
        <div
          className="cursor-pointer flex justify-center items-center align-middle"
          onClick={() => setToggleSearch(!toggleSearch)}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </div>
      </div>

      {toggleSearch && searchTerm && (
        <div className="absolute mt-4 bg-white border rounded shadow-lg w-full min-w-[400px] z-10 top-5 text-text font-inter">
          {filteredTasks.map((task) => (
            <div
              key={task.id}
              className={`task-item p-2 hover:bg-gray-200 cursor-pointer my-1 font-inter border border-gray-100 }`}
              onClick={() => toTask(task.id)}
            >
              <div className="flex items-end allign-middle gap-1 justify-between">
                <div className="font-semibold font-inter text-text text-[14px]">
                  {task.title}{" "}
                  {task.hasAttachments && (
                    <FontAwesomeIcon className="text-muted text-[10px]" icon={faPaperclip} />
                  )}
                </div>
                <div className="text-[12px] font-base">
                  {currentOrganization?.name}-{task.id}
                </div>
              </div>
              <div className="flex gap-1">
                <div className="text-[12px] font-base flex gap-1 align-middle items-center">
                  {/* <div className="text-base"><FontAwesomeIcon icon={faClipboardUser} /></div> */}
                  <div className="">Participant: </div>
                  <div>{task.participant.firstName + " " + task.participant.lastName}</div>
                </div>
              </div>
              
              <div className="flex items-end allign-middle gap-1">
                <p className="text-[10px] truncate text-muted">
                  {task.description}
                </p>
              </div>
              {/* Add other task details as needed */}
            </div>
          ))}
          {filteredTasks.length === 0 && (
            <div className="p-2 text-gray-500 text-xs">No tasks found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskSearch;

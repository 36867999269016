// src/components/tag/TagList.tsx
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../firebase/AuthProvider";
import {
  deleteTag,
  getAllTags,
} from "../../../../services/tagServices/TagService";
import Tag from "../../../../interfaces/tag/Tag";
import TagBadge from "../../../ui/Tag/TagBadge";
import ConfirmDeleteModal from "../../../ui/ConfirmationModals/ConfirmDeleteModal";

const TagList: React.FC = () => {
  const [tags, setTags] = useState<Tag[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      (async () => {
        try {
          const authToken = await currentUser.getIdToken();
          const fetchedTags = await getAllTags(authToken);
          if (!fetchedTags) {
            setError("Failed to load tags.");
            return;
          }
          setTags(fetchedTags);
        } catch (err: any) {
          console.error(err);
          setError(err.toString());
        }
      })();
    }
  }, [currentUser]);

  const handleDelete = async (tagId: number) => {
    if (!currentUser) return;
    if (!window.confirm("Are you sure you want to delete this tag?")) return;

    const authToken = await currentUser.getIdToken();
    const success = await deleteTag(authToken, tagId);
    if (success) {
      // Refresh the list by removing the deleted tag
      setTags(tags?.filter((t) => t.id !== tagId) || null);
    } else {
      setError("Failed to delete tag.");
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="relative bg-background dark:bg-background-dark sm:min-w-[640px] overflow-x-hidden max-w-full flex-grow flex-wrap">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="relative -top-64 -mt-10 bg-white min-h-[350px] w-11/12 mx-auto p-5 inter-font text-text rounded-3xl shadow-lg border">
        <h1 className="text-xl inter-font text-text font-bold mb-4">
          All Tags
        </h1>
        <Link
          to="/tags/new"
          className="bg-brandSecondary text-white px-3 py-1 rounded hover:bg-green-600"
        >
          Create New Tag
        </Link>

        <ul className="mt-4 space-y-2">
          {tags?.map((tag) => (
            <li key={tag.id} className="border p-2 flex justify-between">
              <div className="flex gap-2 align-middle items-center">
                <TagBadge name={tag.name} color={tag.color}/>
                <div className="text-sm text-muted">{tag.color ? <em>({tag.color})</em> : null}</div>
              </div>
              <span>
                <Link
                  to={`/tags/edit/${tag.id}`}
                  className="text-blue-500 hover:underline mr-4"
                >
                  Edit
                </Link>
                <button
                  onClick={() => handleDelete(tag.id)}
                  className="text-red-500 hover:underline"
                >
                  Delete
                </button>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TagList;

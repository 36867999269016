import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import TaskCreationLKValues from "../../../../interfaces/lkValues/TaskCreationLKValues";
import { useAuth } from "../../../../firebase/AuthProvider";
import { getTaskLKValues } from "../../../../services/taskServices/TaskLKService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faAnglesUp,
} from "@fortawesome/free-solid-svg-icons";
import { publishTask } from "../../../../services/taskServices/TaskService";
import NewTask from "../../../../interfaces/task/NewTask";
import { useNavigate } from "react-router-dom";
import {
  getAllParticipants,
  getAssignedParticipants,
} from "../../../../services/participantServices/ParticipantService";
import Participant from "../../../../interfaces/Participant";
import TaskWorkflowGroup from "../../../../interfaces/task/workflow/TaskWorkflowGroup";
import { getWorkflowGroups } from "../../../../services/taskServices/TaskWorkflowServices";

interface OptionType {
  value: string;
  label: string;
}

const TaskCreation: React.FC = () => {
  const { currentUser, currentOrganization, currentRoles } = useAuth();
  const navigate = useNavigate();

  const [isSC, setIsSC] = useState<boolean>(false);

  const [ready, setReady] = useState<boolean>(false);
  const [taskOrganizationOptions, setTaskOrganizationOptions] = useState<
    OptionType[]
  >([]);
  const [participantOptions, setParticipantOptions] = useState<OptionType[]>(
    []
  );
  const [taskTypeOptions, setTaskTypeOptions] = useState<OptionType[]>([]);
  const [taskSubTypeOptions, setTaskSubTypeOptions] = useState<OptionType[]>(
    []
  );
  const [assigneeOptions, setAssigneeOptions] = useState<OptionType[]>([]);
  const [workflowGroupOptions, setWorkflowGroupOptions] = useState<
    OptionType[]
  >([]);
  const [requiredFilesOptions, setRequiredFilesOptions] = useState<
    OptionType[]
  >([]);

  const [newTask, setNewTask] = useState<NewTask>({
    title: "",
    description: "",
    requiredActions: "",
    requiredFiles: [],
    assignee: undefined,
    coAssignee: undefined,
    participant: undefined,
    taskOrganization: "1",
    taskType: "1",
    taskSubType: "4",
    dueDate: "",
    workflowGroup: "Standard",
    priority: "Low",
  });

  const priorityOptions = [
    {
      value: "High",
      label: (
        <span>
          <FontAwesomeIcon className="text-danger" icon={faAnglesUp} /> High
        </span>
      ),
    },
    {
      value: "Medium",
      label: (
        <span>
          <FontAwesomeIcon icon={faAngleUp} className="text-warning" /> Medium
        </span>
      ),
    },
    {
      value: "Low",
      label: (
        <span>
          <FontAwesomeIcon icon={faAngleDown} className="text-ready" /> Low
        </span>
      ),
    },
  ];

  const [lkOptions, setLkOptions] = useState<
    TaskCreationLKValues | null | undefined
  >();
  const [participants, setParticipants] = useState<
    Participant[] | null | undefined
  >();
  const [workflowGroups, setWorkflowGroups] =
    useState<TaskWorkflowGroup | null>();

  useEffect(() => {
    // Fetch the task with taskId and update the state
    const fetchLkValues = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedValues: TaskCreationLKValues | null =
          await getTaskLKValues(authToken);
        const fetchedParticipants: Participant[] | null =
          await getAllParticipants(authToken);
        const fetchedWorkflowGroups: TaskWorkflowGroup | null =
          await getWorkflowGroups(authToken);
        setLkOptions(fetchedValues);
        setParticipants(fetchedParticipants);
        setWorkflowGroups(fetchedWorkflowGroups);
      }
    };

    const isSCHighestRole = currentRoles?.length === 1 && currentRoles[0].name === "SC" ? true : false;
    setIsSC(isSCHighestRole);

    if (lkOptions) {
      const formattedTaskOrganizations = lkOptions.taskOrganizations.map(
        (org) => ({
          value: org.id.toString(),
          label: org.type,
        })
      );
      const formattedTaskTypes = lkOptions.taskTypes.map((tt) => ({
        value: tt.id.toString(),
        label: tt.type,
      }));
      const formattedTaskSubTypes = lkOptions.taskSubTypes.map((tst) => ({
        value: tst.id.toString(),
        label: tst.type,
      }));
      const formattedAssignees = lkOptions.users.map((user) => ({
        value: user.id.toString(),
        label: user.displayName,
      }));
      const formattedParticipants = participants?.map((part) => ({
        value: part.id.toString(),
        label: part.firstName + " " + part.lastName,
      }));
      if (workflowGroups !== undefined && workflowGroups !== null)
        mapTaskWorkflowGroupToOptions(workflowGroups);
        setTaskSubTypeOptions(formattedTaskSubTypes);
        if (isSCHighestRole && currentUser) { 
          setAssigneeOptions([{
            value: currentUser?.uid,
            label: currentUser?.displayName ? currentUser.displayName : 'Assign to yourself',
          }])
        } else { 
          setAssigneeOptions(formattedAssignees);
        }
        setTaskTypeOptions(formattedTaskTypes);
        setTaskOrganizationOptions(formattedTaskOrganizations);
      if (formattedParticipants) {
        setParticipantOptions(formattedParticipants);
      }
    }
    if (!lkOptions) {
      fetchLkValues();
    }
  }, [lkOptions]);

  useEffect(() => {
    validateFields();
  }, [newTask]);

  function mapTaskWorkflowGroupToOptions(taskWorkflowGroup: TaskWorkflowGroup) {
    let options: { value: string; label: string }[] = [];

    // Iterate over each flowGroup and its elements
    for (const flowGroup in taskWorkflowGroup) {
      const label = `${flowGroup}`;
      options.push({ value: flowGroup.toString(), label: label });
    }

    setWorkflowGroupOptions(options);
  }

  const handleTaskOrganizationChange = (
    selectedOption: SingleValue<OptionType>
  ) => {
    const taskOrganization = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, taskOrganization });
  };

  const handleWorkflowGroupChange = (
    selectedOption: SingleValue<OptionType>
  ) => {
    const workflowGroup = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, workflowGroup });
  };

  const handleTaskTypeChange = (selectedOption: SingleValue<OptionType>) => {
    const taskType = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, taskType });
  };

  const handleTaskSubTypeChange = (selectedOption: SingleValue<OptionType>) => {
    const taskSubType = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, taskSubType });
  };

  const handleAssigneeChange = (selectedOption: SingleValue<OptionType>) => {
    const assignee = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, assignee });
  };

  const handleParticipantChange = (selectedOption: SingleValue<OptionType>) => {
    const participant = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, participant });
  };

  const handleCoAssigneeChange = (selectedOption: SingleValue<OptionType>) => {
    const coAssignee = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, coAssignee });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setNewTask({ ...newTask, [name]: value });
  };

  const handleRequiredFileChanges = (selectedOptions: OptionType[]) => {
    const requiredFiles = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setNewTask({ ...newTask, requiredFiles });
  };

  const handleCustomChange = (
    fieldName: keyof NewTask,
    fieldValue: string | undefined
  ) => {
    setNewTask({ ...newTask, [fieldName]: fieldValue });
  };

  const validateFields = () => { 
    if (newTask.title === "" || newTask.title === undefined) { 
      setReady(false);
    } else if (newTask.description === "" || newTask.description === undefined) { 
      setReady(false);
    } else if (newTask.assignee === undefined) { 
      setReady(false);
    } else if (newTask.participant === undefined) { 
      setReady(false);
    }
    else { 
      console.log('READY')
      setReady(true);
    }
  }

  const handleSubmit = () => {

    if (ready) { 
      // Handle submitting the new task
      const createTask = async () => {
        if (currentUser !== null) {
          const authToken: string = await currentUser.getIdToken();
          const newTaskId: number | null = await publishTask(authToken, newTask);
          return newTaskId;
        }
        return null;
      };
      const idPromise = createTask();
  
      idPromise.then((id) => {
        if (id !== null) {
          // Redirect to new task here.
          console.log("New task ID:", id);
          navigate(`/task/${currentOrganization?.name}-${id}`);
        } else {
          console.error("Failed to create task");
        }
      });
    } else { 
      console.log('NOT READY')
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewTask({ ...newTask, [name]: value });
  };

  return (
    <div className="mt-5 remove-input-txt-border">
      <h1 className="inter-font text-text text-3xl mt-5 text-center">
        Create New Interim Task
      </h1>
      <div
        className="max-w-xl mx-auto mt-8  dark:text-text dark:bg-card-dark  rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            htmlFor="title"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Title:
          </label>
          <input
            type="text"
            id="title"
            name="title"
            placeholder="Give your task a title..."
            value={newTask.title}
            onChange={handleChange}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-text dark:text-text text-sm font-bold mb-2"
          >
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            placeholder="A brief description of what the task entails..."
            value={newTask.description}
            onChange={handleChange}
            required
            className="shadow resize-none appearance-none border rounded w-full py-2 px-3 text-text dark:text-text leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="assignee"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Participant:
          </label>
          <Select
            id="participant"
            name="participant"
            value={participantOptions.find(
              (option) => option.value === newTask.participant
            )}
            onChange={handleParticipantChange}
            options={participantOptions}
            className="your-custom-class"
            classNamePrefix="select"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="assignee"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Assignee:
          </label>
          <Select
            id="assignee"
            name="assignee"
            value={assigneeOptions.find(
              (option) => option.value === newTask.assignee
            )}
            onChange={handleAssigneeChange}
            options={assigneeOptions}
            className="your-custom-class"
            classNamePrefix="select"
          />
        </div>
        {newTask.assignee && !isSC && (
          <div className="mb-4">
            <label
              htmlFor="coAssignee"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Co-Assignee{" "}
              <span className="text-muted font-normal">(Optional)</span>:
            </label>
            <Select
              id="coAssignee"
              name="coAssignee"
              value={assigneeOptions.find(
                (option) => option.value === newTask.coAssignee
              )}
              onChange={handleCoAssigneeChange}
              options={assigneeOptions?.filter(
                (user) => user.value !== newTask.assignee
              )}
              classNamePrefix="select"
            />
          </div>
        )}
        {/* <div className="mb-4">
          <label
            htmlFor="requiredFiles"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Required Files{" "}
            <span className="text-muted font-normal">(Optional)</span>:
          </label>
          <Select
            options={requiredFilesOptions}
            isMulti
            onChange={(e) => handleRequiredFileChanges}
            value={requiredFilesOptions.filter((option) =>
              newTask.requiredFiles.includes(option.value)
            )}
          />
        </div> */}
        <div className="mb-4">
          <label
            htmlFor="dueDate"
            className="block text-text dark:text-text-dark text-sm font-bold mb-2"
          >
            Due Date:
          </label>
          <input
            type="date"
            id="dueDate"
            name="dueDate"
            value={newTask.dueDate}
            onChange={handleDateChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-text leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex justify-end mt-10">
        <button
          onClick={handleSubmit}
          type="button"
            className="bg-[#3dd598] w-full rounded-xl text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
          >
            Create Task
          </button>
          </div>
      </div>
    </div>
  );
};

export default TaskCreation;

// Navbar.tsx
import {
  faAt,
  faBell,
  faBolt,
  faCheck,
  faExclamation,
  faInfo,
  faList,
  faRightFromBracket,
  faUser,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
// import DarkModeToggle from "../../ui/DarkModeToggle/DarkModeToggle";
import "./Navbar.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NotificationDropDown from "../../components/notifications/NotificationDropdown";
import {
  getLatestNotifications,
  getNotifications,
} from "../../../services/notificationServices/NotificationService";
import { useAuth } from "../../../firebase/AuthProvider";
import EventNotification from "../../../interfaces/eventNotification/EventNotification";
import { User } from "../../../interfaces/User";
import TaskSearch from "../../components/search/TaskSearch";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";

interface NavbarProps {
  toggleDarkMode: () => void;
  isDarkMode: boolean;
}

interface NavItemProps {
  name: string;
  icon: IconDefinition;
  onClick: () => void;
  path: string;
}

const NavItem: React.FC<NavItemProps & { isActive: boolean }> = ({
  name,
  icon,
  isActive,
  onClick,
  path,
}) => {
  return (
    <Link
      to={path}
      className={`flex items-center p-2 cursor-pointer`}
      onClick={onClick}
    >
      <span className="mr-2 text-xl">
        <FontAwesomeIcon icon={icon} />
      </span>
      <span>{name}</span>
    </Link>
  );
};

const Navbar: React.FC<NavbarProps> = ({ toggleDarkMode, isDarkMode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [addToggle, setAddToggle] = useState(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [notifications, setNotifications] = useState<EventNotification[]>([]);
  const [newNotifications, setNewNotifications] = useState<EventNotification[]>(
    []
  );
  const [taskId, setTaskId] = useState<string>("");
  const [displayMemberList, setDisplayMemberList] = useState<boolean>(false);
  const [team, setTeam] = useState<User[]>([]);
  const [currentAvatar, setCurrentAvatar] = useState<Avatar | null>(null);
  const pollInterval = 30000; // 30 seconds
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Track the initial load

  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, currentRoles } = useAuth();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleNotificationsMenu = () => {
    setAddToggle(false);
    setDisplayMemberList(false);
    setIsNotificationMenuOpen(!isNotificationMenuOpen);
  };

  const toggleAddMenu = () => {
    setDisplayMemberList(false);
    setIsNotificationMenuOpen(false);
    setAddToggle(!addToggle);
  };

  useEffect(() => {
    setAddToggle(false);
    setIsNotificationMenuOpen(false);

    const extractTaskId = () => {
      const match = location.pathname.match(/\/task\/([^\/]+)/);
      if (match) {
        const id = match[1];
        setTaskId(id);
        // Use taskId for any logic here
      } else {
        setTaskId("");
      }
    };

    extractTaskId();
    // Get notifications on a polling timer
  }, [location]);

  useEffect(() => {
    if (currentUser) {
      const getNotificationsForUser = async () => {
        const authToken = await currentUser.getIdToken();
        const response = await getNotifications(authToken);
        if (response !== undefined && response !== null) {
          setNotifications(response);
        }
      };
      getNotificationsForUser();
    }
  }, [currentUser]);

  useEffect(() => {
    const pollNotifications = async () => {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        setNewNotifications([]);
        const newNotifications = await getLatestNotifications(authToken);
  
        if (newNotifications && newNotifications.length > 0) {
          setNewNotifications(newNotifications);
  
          // Prepend new notifications and keep only the top 5
          setNotifications((prevNotifications) => {
            const combinedNotifications = [
              ...newNotifications,
              ...prevNotifications,
            ];
  
            // Return only the latest 5 notifications
            return combinedNotifications.slice(0, 5);
          });
  
          if (!isInitialLoad) {
            playNotificationSound(); // Play sound for new notifications
          }
        }
      }
  
      if (isInitialLoad) {
        setIsInitialLoad(false);
      }
    };
  
    pollNotifications(); // Initial fetch when the component mounts
  
    const interval = setInterval(() => {
      pollNotifications();
    }, pollInterval);
  
    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, [currentUser]);


  useEffect(() => {
    if (currentUser) {
      const storedAvatars = getAvatarsFromStorage();
      if (storedAvatars) {
        const avatar = storedAvatars.find(
          (avatar) => avatar.id === currentUser.uid
        );
        if (avatar) {
          setCurrentAvatar(avatar);
        }
      }
    }
  }, [currentUser]);

  type ReplacementType = {
    [key: string]: string;
  };

  const renderMessageWithLinks = (
    message: string,
    notification: EventNotification
  ) => {
    const replacements: ReplacementType = {
      "{task}": "task",
      "{author}": "user",
      "{file}": "file",
      "{activity}": "task_action",
    };

    const parts = message.split(/(\{[a-zA-Z]+\})/g); // Split the message by placeholders
    return parts.map((part, index) => {
      if (replacements[part]) {
        const type = replacements[part];
        const resource = notification.notificationResources?.find(
          (res: any) => res.resourceType === type
        );
        if (type === "task_action") {
          return (
            <span className="clamp-3" key={index}>
              {notification.notificationResources?.find(
                (res: any) => res.resourceType === type
              )?.resourceName || "Activity"}
            </span>
          );
        }
        if (type === "user") {
          return (
            <span className="clamp-3" key={index}>
              {notification.notificationResources?.find(
                (res: any) => res.resourceType === type
              )?.resourceName || "Activity"}
            </span>
          );
        }
        if (type === "task") {
          return (
            <span className="clamp-3" key={index}>
              {notification.notificationResources?.find(
                (res: any) => res.resourceType === type
              )?.resourceName || "Activity"}
            </span>
          );
        }
        if (resource) {
          return (
            <span
              className="inline clamp-3"
              key={index}
              // href={resource.resourceId}
            >
              {resource.resourceName}
            </span>
          );
        }
      }
      return <span key={index}>{part}</span>; // If no replacement, return the part as is
    });
  };

  const playNotificationSound = () => {
    // Retrieve the user's preferred sound from local storage
    const soundFileName =
      localStorage.getItem("notificationSound") || "notification-4.mp3";

    const audio = new Audio(`/notificationTones/${soundFileName}`); // Assuming the files are in the public folder
    audio
      .play()
      .then(() => {
        console.log("Playing notification sound!");
      })
      .catch((error) => {
        console.error("Error playing sound:", error);
      });
  };

  const dismissNewNotification = (id: string) => {
    setNewNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  return (
    <div>
      {currentUser && (
        <div className="bg-navBackground dark:bg-navBackground-dark h-[127px] ">
          <nav className="bg-black p-4 dark:bg-navBackground-dark text-white dark:text-text-dark main-nav ">
            <div className="container mx-auto flex justify-between items-center">
              {/* Logo */}
              <Link
                to={"/profile/edit"}
                className=" text-lg font-bold outfit-font flex items-center gap-4"
              >
                <div>
                  {currentAvatar ? (
                    <img
                      src={`data:image/png;base64,${currentAvatar.avatar}`}
                      className="h-[40px] w-[40px] rounded-full"
                    ></img>
                  ) : (
                    <div className="w-[30px] h-[30px] bg-darkTogglebg-dark rounded-full flex items-center justify-center">
                      <FontAwesomeIcon icon={faUser} className="text-base" />
                    </div>
                  )}
                </div>
                {
                  currentRoles?.reduce(
                    (maxIdObject, obj) =>
                      obj.id > maxIdObject.id ? obj : maxIdObject,
                    currentRoles[currentRoles.length - 1]
                  )?.name
                }
              </Link>

              {/* Mobile Menu Toggle (Hidden by default) */}
              <div className="md:hidden">
                <button className=" focus:outline-none" onClick={toggleMenu}>
                  <i className={`fas ${isOpen ? "fa-times" : "fa-bars"}`}></i>
                </button>
              </div>

              {/* Navigation Links (Desktop) */}
              <ul className="hidden md:flex space-x-4">
                <li className="d-flex items-center align-middle my-auto">
                  <TaskSearch />
                </li>
                {/* @TODO: Update Region to be a selectable toggle for all regions associated with a User */}
                <li className="d-flex items-center align-middle my-auto">
                  <div className="text-xs region-bullet text-white inter-font flex justify-center align-middle items-center">
                    Region: 2
                  </div>
                </li>
                <li className="relative d-flex justify-center items-center align-middle my-auto cursor-pointer  w-8 h-8">
                  <div
                    className="text-2xl w-full mx-auto text-center hover:bg-navbarDark dark:hover:bg-text rounded-3xl"
                    onClick={() => toggleAddMenu()}
                  >
                    +
                  </div>
                  {addToggle && (
                    <div className="absolute bg-card dark:bg-card-dark p-2 rounded-lg shadow-lg min-w-[150px] right-0 mt-5 text-text">
                      <div
                        className="text-center hover:bg-background dark:hover:bg-background-dark"
                        onClick={() =>
                          navigate(
                            taskId === "" || taskId === "new"
                              ? `task/new/activity`
                              : `task/new/activity?taskId=${
                                  taskId.split("-")[1]
                                }`
                          )
                        }
                      >
                        Add Activity
                      </div>
                      <div
                        className="text-center hover:bg-background dark:hover:bg-background-dark"
                        onClick={() => navigate("task/new/interim")}
                      >
                        Add Task
                      </div>
                    </div>
                  )}
                </li>
                {/* <li className="">
                  <DarkModeToggle
                    toggleDarkMode={toggleDarkMode}
                    isDarkMode={isDarkMode}
                  />
                </li> */}
                <li className="flex justify-center align-middle items-center">
                  <div className="flex">
                    <div
                      className="hover:bg-gray-200  bg-white flex items-center rounded-3xl w-7 h-7 justify-center cursor-pointer"
                      onClick={toggleNotificationsMenu}
                    >
                      <FontAwesomeIcon
                        className="my-auto text-center text-base text-muted"
                        icon={faBell}
                      />
                    </div>
                    {notifications.filter((n) => n.read === false).length >
                      0 && (
                      <div className="rounded-full h-2.5 w-2.5 bg-red-600 text-[8px] "></div>
                    )}
                    {isNotificationMenuOpen && (
                      <div className="relative top-10">
                        <NotificationDropDown notifications={notifications} />
                      </div>
                    )}
                  </div>
                </li>
                <li
                  className="flex items-center justify-center align-middle cursor-pointer hover:text-brandSecondary"
                  onClick={() => navigate("/login")}
                >
                  <div className="flex items-center justify-center align-middle gap-2 text-base inter-font">
                    <FontAwesomeIcon icon={faRightFromBracket} />
                    <div>Logout</div>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <div className="flex flex-row gap-10 w-4/5 mx-auto mt-[20px] container secondary-nav">
                <div
                  onClick={() => navigate("/home")}
                  className={`${
                    location.pathname.startsWith("/home") ||
                    location.pathname.startsWith("/dashboard")
                      ? "text-brandSecondary"
                      : ""
                  } cursor-pointer hover:text-brandSecondary `}
                >
                  <div className="text-sm">Dashboard</div>
                  <div>
                    {location.pathname.startsWith("/home") ||
                    location.pathname.startsWith("/dashboard") ? (
                      <div className="mt-1 h-[3px] w-full bg-brandSecondary rounded-full"></div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div
                  onClick={() => navigate("/tasks")}
                  className={`${
                    location.pathname.startsWith("/tasks") ||
                    location.pathname.startsWith("/task")
                      ? "text-brandSecondary border-b-brandSecondary"
                      : ""
                  } cursor-pointer hover:text-brandSecondary `}
                >
                  <div className="text-sm">Tasks</div>
                  <div>
                    {location.pathname.startsWith("/tasks") ||
                    location.pathname.startsWith("/task") ? (
                      <div className="mt-1 h-[3px] w-full bg-brandSecondary rounded-full"></div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div
                  onClick={() => navigate("/drive/caseFileDrive")}
                  className={`${
                    location.pathname.startsWith(`/drive`)
                      ? "text-brandSecondary border-b-brandSecondary"
                      : ""
                  } cursor-pointer hover:text-brandSecondary `}
                >
                  <div className="text-sm">Drive</div>
                  <div>
                    {location.pathname.startsWith(`/drive`) ? (
                      <div className="mt-1 h-[3px] w-full bg-brandSecondary rounded-full"></div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile Menu (Hidden by default) */}
            <div className={`md:hidden ${isOpen ? "block" : "hidden"}`}>
              <ul className="flex flex-col space-y-2">
                <li className="d-flex align-center my-auto">
                  <div className="text-xs text-muted">Region: 2</div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}

      {newNotifications.length > 0 && (
        <div className="fixed right-3 bottom-5 z-40  inter-font flex flex-col gap-5 rounded-3xl text-xs">
          {newNotifications.map((notification) => (
            <div
              className="p-3 pt-4 flex justify-between shadow-lg text-text bg-background border w-[300px] h-20 rounded-3xl"
              style={{
                animation: `fadeOut 30s forwards`,
              }}
              key={notification.id}
            >
              {notification.status === "error" && (
                <div className="rounded-full bg-red-600 relative -top-8 shadow shadow-gray-400 w-10 h-10 flex justify-center items-center z-50 text-white">
                  <FontAwesomeIcon className="text-base" icon={faExclamation} />
                </div>
              )}
              {notification.status === "info" &&
                notification.type !== "mention" && (
                  <div className="rounded-full bg-[#3f62a1] relative -top-8 shadow shadow-gray-400 w-10 h-10 flex justify-center items-center z-50 text-white">
                    <FontAwesomeIcon className="text-base" icon={faInfo} />
                  </div>
                )}
              {notification.status === "info" &&
                notification.type === "mention" && (
                  <div className="rounded-full bg-[#3f62a1] relative -top-8 shadow shadow-gray-400 w-10 h-10 flex justify-center items-center z-50 text-white">
                    <FontAwesomeIcon className="text-base" icon={faAt} />
                  </div>
                )}
              {notification.status === "success" && (
                <div className="rounded-full bg-green-700 relative -top-8 shadow shadow-gray-400 w-10 h-10 flex justify-center items-center z-50 text-white">
                  <FontAwesomeIcon className="text-base" icon={faCheck} />
                </div>
              )}
              <div className="w-2/3 text-start flex flex-col relative -top-2 text-xs">
                {notification.status === "error" && (
                    <div className="mb-1 font-bold">Oh no!</div>
                )}
                {notification.status === "info" &&
                  notification.type !== "mention" && (
                    <div className="mb-1 font-bold">Notification!</div>
                  )}
                {notification.status === "info" &&
                  notification.type === "mention" && (
                    <div className="mb-1 font-bold">New Mention!</div>
                  )}
                {notification.status === "success" && (
                  <div className="mb-1 font-bold">Good news!</div>
                )}{" "}
                <div className="text-xs line-clamp-4">
                  {renderMessageWithLinks(notification.message, notification)}
                </div>
              </div>
              <div className="cursor-pointer">
                <FontAwesomeIcon
                  icon={faX}
                  className="hover:bg-gray-200 rounded-full p-2 relative -top-3"
                  onClick={() => dismissNewNotification(notification.id)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Navbar;

import React, { useEffect, useState } from "react";
import TaskAction from "../../../../interfaces/task/taskActions/TaskAction";
import { useAuth } from "../../../../firebase/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAllActivitiesForUser } from "../../../../services/taskServices/TaskActionService";
import TaskActivity from "../../taskPage/TaskActivity";
import {
  faBolt,
  faBook,
  faCar,
  faCheckCircle,
  faCircleInfo,
  faCircleUser,
  faComments,
  faEnvelope,
  faFax,
  faFileUpload,
  faMagnifyingGlass,
  faPen,
  faPhone,
  faSearch,
  faTimesCircle,
  faTrashAlt,
  faUpRightFromSquare,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserInfo from "../../../../interfaces/UserInfo";
import { getTimeAgo } from "../../../../utilities/dateUtils";
import { getAllUsersInOrganization } from "../../../../services/userServices/UserInfoService";
import Pagination from "../../../ui/Pagination/Pagination";

const parseTaskActionDescription = (
  activity: TaskAction,
  users: UserInfo[] | null,
  navigate: (path: string) => void
): string | JSX.Element => {
  const { actionSubType, description, author } = activity;

  const renderDescription = (chat: TaskAction) => {
    if (!chat.description) {
      return null;
    }

    let parts = chat.description.split(/({user:[^}]+}|\n)/g);

    return parts.map((part, i) => {
      if (part === "\n") {
        return <br key={i} />;
      }
      const match = part.match(/{user:(.+)}/);
      if (match) {
        const userId = match[1];
        const mentionedUser = users?.find((user) => user.id === userId);

        if (mentionedUser) {
          return (
            <span
              key={i}
              className="bg-blue-100 text-blue-700 rounded p-0.5 cursor-pointer mx-0.5 text-sm px-1"
              onClick={() => navigate(`/profile/user/${mentionedUser.id}`)}
            >
              <FontAwesomeIcon icon={faCircleUser} className="mr-1" />
              {mentionedUser.displayName}
            </span>
          );
        } else {
          return `{Unknown User}`;
        }
      } else {
        return part;
      }
    });
  };

  switch (actionSubType.actionSubTypeName) {
    case "COMMENT":
      const commenter = users?.find((user) => user.id === author?.id);
      const commentText = description || "No comment provided.";
      return (
        <>
          {commenter ? (
            <span className="font-bold">{commenter.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          commented on task{" "}
          <span
            className="font-bold text-blue-500 cursor-pointer"
            onClick={() =>
              navigate(
                `/task/${commenter?.organization.name}-${activity.taskId}`
              )
            }
          >
            {commenter?.organization.name + "-" + activity.taskId}
          </span>
          : "{renderDescription(activity)}"
        </>
      );

    case "TASK COMPLETED":
      const completer = users?.find((user) => user.id === author?.id);
      return (
        <>
          {completer ? (
            <span className="font-bold">{completer.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          completed the task{" "}
          <span className="font-bold">
            {completer?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "FILE UPLOAD":
      const uploader = users?.find((user) => user.id === author?.id);
      return (
        <>
          {uploader ? (
            <span className="font-bold">{uploader.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          uploaded a file to task{" "}
          <span className="font-bold">
            {uploader?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "TASK APPROVAL":
      const approver = users?.find((user) => user.id === author?.id);
      return (
        <>
          {approver ? (
            <span className="font-bold">{approver.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          approved the task{" "}
          <span className="font-bold">
            {approver?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "TASK REJECTED":
      const rejecter = users?.find((user) => user.id === author?.id);
      return (
        <>
          {rejecter ? (
            <span className="font-bold">{rejecter.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          rejected the task{" "}
          <span className="font-bold">
            {rejecter?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "TASK SUBMISSION":
      const submitter = users?.find((user) => user.id === author?.id);
      return (
        <>
          {submitter ? (
            <span className="font-bold">{submitter.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          submitted the task{" "}
          <span
            className="font-bold text-blue-500 cursor-pointer"
            onClick={() =>
              navigate(
                `/task/${submitter?.organization.name}-${activity.taskId}`
              )
            }
          >
            {submitter?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "TASK FILE DELETED":
      const deleter = users?.find((user) => user.id === author?.id);
      return (
        <>
          {deleter ? (
            <span className="font-bold">{deleter.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          deleted a file from task{" "}
          <span className="font-bold">
            {deleter?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "Email":
      const emailSender = users?.find((user) => user.id === author?.id);
      return (
        <>
          {emailSender ? (
            <span className="font-bold">{emailSender.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          sent an email related to task{" "}
          <span className="font-bold">
            {emailSender?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    case "Phone Call":
      const caller = users?.find((user) => user.id === author?.id);
      return (
        <>
          {caller ? (
            <span className="font-bold">{caller.displayName}</span>
          ) : (
            "Someone"
          )}{" "}
          made a phone call related to task{" "}
          <span className="font-bold text-blue-500">
            {caller?.organization.name + "-" + activity.taskId}
          </span>
          .
        </>
      );

    default:
      return description || "No additional details available.";
  }
};

const UserProfileActivities: React.FC = () => {
  const { currentUser } = useAuth();
  const { userId } = useParams();

  const navigate = useNavigate();

  const [activities, setActivities] = useState<TaskAction[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10); // Fixed page size for simplicity
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserInfo[] | null>([]);

  useEffect(() => {
    if (!currentUser) return;
    const fetchTaskActions = async () => {
      setLoading(true);
      const authToken: string = await currentUser.getIdToken();
      const result = await fetchAllActivitiesForUser(
        authToken,
        userId,
        pageNumber,
        pageSize
      );

      if (result) {
        setActivities(result.items);
        setTotalCount(result.totalCount);
      }
      setLoading(false);
    };

    const fetchUsers = async () => {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        const fetchedUsers = await getAllUsersInOrganization(authToken);
        setUsers(fetchedUsers);
      }
    };

    fetchUsers();
    fetchTaskActions();
  }, [userId, pageNumber, pageSize]);

  const changePage = (page: number) => {
    setPageNumber(page); // Use functional update to ensure the correct value
  };

  const getIconForActionSubtype = (actionSubtype: string) => {
    const icons: { [key: string]: any } = {
      "FILE UPLOAD": faFileUpload,
      COMMENT: faComments,
      "TASK APPROVAL": faCheckCircle,
      "TASK SUBMISSION": faMagnifyingGlass,
      "TASK COMPLETED": faCheckCircle,
      "TASK FILE DELETED": faTrashAlt,
      Email: faEnvelope,
      Fax: faFax,
      "Phone Call": faPhone,
      "Report Writing": faPen,
      "Resource Research": faSearch,
      Other: faBolt,
      Documentation: faBook,
      REJECTED: faTimesCircle,
      Meeting: faUsers,
      Travel: faCar,
      Logging: faPen,
    };

    return icons[actionSubtype] || faCircleInfo; // Default icon if subtype not found
  };

  const navigateFunction = (path: string) => {
    navigate(path);
  };

  return (
    <div>
      {loading && <p>Loading...</p>}
      <div className="latest-activity inter-font p-2 mt-5 px-5 rounded-md">
        <div className="flex gap-3 align-middle items-center">
          <div className="font-bold">Latest Activity</div>
        </div>
        <div className="activity-list border-l flex flex-col px-5 py-2 mx-2 ms-5">
          {activities.length > 0 &&
            activities.map((activity, index) => (
              <div
                className="activity-list-item flex items-center align-middle gap-2 -ms-12 "
                key={index}
              >
                <div className="flex items-center align-middle">
                  <div>
                    <div className="m-1 z-40 bg-white w-12 h-12 flex items-center justify-center">
                      <div className="activity-icon text-blue-500 rounded-full h-7 w-7 flex justify-center items-center bg-blue-100">
                        <FontAwesomeIcon
                          icon={getIconForActionSubtype(
                            activity.actionSubType.actionSubTypeName
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="activity-description text-muted text-xs">
                      {parseTaskActionDescription(
                        activity,
                        users,
                        navigateFunction
                      )}
                    </div>
                    <div className="text-gray-400 text-2xs">
                      {getTimeAgo(activity.createDate)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
          <div>
            <div className="flex justify-center align-middle items-center">
              <Pagination
                currentPage={pageNumber}
                totalPages={Math.ceil(totalCount / pageSize) || 0}
                onPageChange={changePage}
              />
            </div>
          </div>
        {activities.length === 0 && (
          <div className="text-inactive text-xs text-center mt-5">
            There is currenly no activity to display here.
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfileActivities;

import React, { useEffect, useState } from "react";
import {
  DriveItem,
  ViewMode,
} from "../../../../../interfaces/caseFileDrive/DriveFiles";
import { formatFileSize } from "../../../../../utilities/fileUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAudio,
  faFileExcel,
  faFileLines,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFileZipper,
  faFolder,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { getTimeAgo } from "../../../../../utilities/dateUtils";
import { getAvatarsFromStorage } from "../../../../../services/fileServices/FileService";
import Avatar from "../../../../../interfaces/avatar/Avatar";

// Utility function to get file icon based on MIME type
const getFileIcon = (mimeType: string): JSX.Element => {
  if (mimeType.startsWith("image/"))
    return <FontAwesomeIcon icon={faImage} className="text-blue-400" />;
  if (mimeType === "application/pdf")
    return <FontAwesomeIcon icon={faFilePdf} className="text-red-500" />;
  if (mimeType.startsWith("video/"))
    return <FontAwesomeIcon icon={faFileVideo} className="text-purple-400" />;
  if (mimeType.startsWith("audio/"))
    return <FontAwesomeIcon icon={faFileAudio} className="text-green-400" />;
  if (
    mimeType.includes("spreadsheet") ||
    mimeType === "application/vnd.ms-excel"
  )
    return <FontAwesomeIcon icon={faFileExcel} className="text-green-500" />;
  if (mimeType.includes("word") || mimeType === "application/msword")
    return <FontAwesomeIcon icon={faFileWord} className="text-blue-500" />;
  if (
    mimeType.includes("presentation") ||
    mimeType === "application/vnd.ms-powerpoint"
  )
    return (
      <FontAwesomeIcon icon={faFilePowerpoint} className="text-orange-400" />
    );
  if (
    mimeType === "application/zip" ||
    mimeType === "application/x-7z-compressed"
  )
    return <FontAwesomeIcon icon={faFileZipper} className="text-yellow-500" />;
  if (mimeType.startsWith("text/" || mimeType === "application/rtf"))
    return <FontAwesomeIcon icon={faFileLines} className="text-gray-400" />;
  return <FontAwesomeIcon icon={faFolder} className="text-yellow-600" />;
};

interface FileItemProps {
  file: DriveItem;
  viewMode: ViewMode;
  onClick?: () => void;
  onRightClick?: (e: React.MouseEvent) => void;
  renameTarget?: DriveItem | null;
  renameValue?: string;
  setRenameValue?: (val: string) => void;
  doRename?: (newName: string) => void;
}

const FileItem: React.FC<FileItemProps> = ({
  file,
  viewMode,
  onClick,
  onRightClick,
  renameTarget,
  renameValue,
  setRenameValue,
  doRename,
}) => {
  const fileIcon = getFileIcon(file.mimeType || "");
  const [showFullName, setShowFullName] = useState<boolean>(false);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
  const [avatars, setAvatars] = useState<Avatar[] | null>([]);

  const handleMouseEnter = () => {
    setShowFullName(true);
    const timeout = setTimeout(() => {}, 1000);
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setShowFullName(false);
  };

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  const avatarUrl = findAvatarById(file.authorId || "");

  const isRenaming = renameTarget?.id === file.id;

  if (viewMode === "grid") {
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="file-item-grid flex flex-col justify-evenly p-2 m-2 border bg-white border-gray-200 rounded-md text-center text-ellipsis shadow-lg hover:shadow-none w-[125px] h-[125px] relative cursor-pointer"
        onClick={onClick}
        onContextMenu={onRightClick}
      >
        <div className="text-5xl">{fileIcon}</div>
        <div>
          <div className="text-sm truncate inter-font font-bold text-gray-500 mt-5 text-start">
            {file.name}
          </div>
          <div className="text-muted text-xs text-start">
            {formatFileSize(file.size || 0)}
          </div>
        </div>
        {showFullName && (
          <div
            className="absolute bg-gray-700 text-white text-xs rounded px-2 py-1 shadow-lg z-40"
            style={{
              top: "110%",
              left: "50%",
              transform: "translateX(-50%)",
              whiteSpace: "nowrap",
            }}
          >
            {file.name}
          </div>
        )}
      </div>
    );
  }

  // TABLE VIEW
  return (
    <tr
      className="hover:bg-gray-100 cursor-pointer border-t inter-font"
      onClick={onClick}
      onContextMenu={onRightClick}
    >
      <td className="text-center">{fileIcon}</td>
      <td className="text-xs">
        {isRenaming ? (
          <input
            className="border p-1 rounded text-xs w-[150px]"
            value={renameValue}
            onChange={(e) => setRenameValue?.(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && doRename && renameValue) {
                doRename(renameValue);
              }
            }}
            autoFocus
          />
        ) : (
          file.name
        )}
      </td>
      <td>
        {avatarUrl ? (
          <img
            src={avatarUrl}
            alt={`${file.authorFullName}`}
            className="w-7 h-7 rounded-full object-cover"
          />
        ) : (
          <div className="text-sm">{file.authorFullName}</div>
        )}
      </td>
      <td className="text-end text-xs">
        {file.updateDate ? getTimeAgo(file.updateDate) : "--"}
      </td>
      <td className="text-center text-xs">{formatFileSize(file.size || 0)}</td>
    </tr>
  );
};

export default FileItem;

// src/services/tagServices/TagService.ts
import Tag from "../../interfaces/tag/Tag";

// The base URL would align with your .NET routes: "api/tags"
const TAG_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/tags`;

/**
 * Create a new Tag
 */
export async function createTag(
  authToken: string,
  tagData: Omit<Tag, "id">
): Promise<Tag | null> {
  try {
    const response = await fetch(`${TAG_BASE_URL}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tagData),
    });

    if (!response.ok) {
      console.log(await response.json());
      return null;
    }

    // On success, the API returns the created tag
    const tag = (await response.json()) as Tag;
    return tag;
  } catch (error) {
    console.error("Error creating tag:", error);
    return null;
  }
}

/**
 * Get a list of all Tags
 */
export async function getAllTags(authToken: string): Promise<Tag[] | null> {
  try {
    const response = await fetch(`${TAG_BASE_URL}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      console.log(await response.json());
      return null;
    }

    const data = (await response.json()) as Tag[];
    return data;
  } catch (error) {
    console.error("Error fetching all tags:", error);
    return null;
  }
}

/**
 * Get a specific Tag by ID
 */
export async function getTagById(
  authToken: string,
  tagId: number
): Promise<Tag | null> {
  try {
    const response = await fetch(`${TAG_BASE_URL}/${tagId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      console.log(await response.json());
      return null;
    }

    const data = (await response.json()) as Tag;
    return data;
  } catch (error) {
    console.error(`Error fetching tag ${tagId}:`, error);
    return null;
  }
}

/**
 * Update an existing Tag
 */
export async function updateTag(
  authToken: string,
  tagId: number,
  tagData: Omit<Tag, "id">
): Promise<Tag | null> {
  try {
    const response = await fetch(`${TAG_BASE_URL}/${tagId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tagData),
    });

    if (!response.ok) {
      console.log(await response.json());
      return null;
    }

    const data = (await response.json()) as Tag;
    return data;
  } catch (error) {
    console.error(`Error updating tag ${tagId}:`, error);
    return null;
  }
}

/**
 * Delete a Tag by ID
 */
export async function deleteTag(
  authToken: string,
  tagId: number
): Promise<boolean> {
  try {
    const response = await fetch(`${TAG_BASE_URL}/${tagId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      console.log(await response.json());
      return false;
    }

    const success = await response.json(); // or whatever your backend returns
    return success === true || success === "true"; 
  } catch (error) {
    console.error(`Error deleting tag ${tagId}:`, error);
    return false;
  }
}

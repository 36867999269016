// file: DriveContext.tsx
import React, { createContext, useState, useContext } from "react";

// The type for our view mode
export type ViewMode = "grid" | "table";

// The shape of the context value
interface DriveContextValue {
  viewMode: ViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<ViewMode>>;
}

// Create the context with a default placeholder
const DriveContext = createContext<DriveContextValue | undefined>(undefined);

/**
 * Provides the Drive context (viewMode & setter) to child components.
 */
export const DriveProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // The actual state lives here
  const [viewMode, setViewMode] = useState<ViewMode>("table");

  return (
    <DriveContext.Provider value={{ viewMode, setViewMode }}>
      {children}
    </DriveContext.Provider>
  );
};

/**
 * Custom hook to read the DriveContext
 */
export function useDriveContext(): DriveContextValue {
  const context = useContext(DriveContext);
  if (!context) {
    throw new Error("useDriveContext must be used within a DriveProvider");
  }
  return context;
}

import React, { useEffect, useState } from "react";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";
import { useAuth } from "../../../firebase/AuthProvider";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { getUserInfoByUserId } from "../../../services/userServices/UserInfoService";
import UserInfo from "../../../interfaces/UserInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faBuilding,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { toTitleCase } from "../../../utilities/stringUtils";
import UserProfileOverview from "./userProfileComponents/UserProfileOverview";

const UserProfile: React.FC = () => {
  const { currentUser } = useAuth();
  const { userId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [avatars, setAvatars] = useState<Avatar[] | null>([]);
  const [user, setUser] = useState<UserInfo>();

  const activeNavItem = React.useMemo(() => {
    if (location.pathname.endsWith("/tasks")) return "tasks";
    if (location.pathname.endsWith("/documents")) return "documents";
    if (location.pathname.endsWith("/files")) return "files";
    if (location.pathname.endsWith("/activities")) return "activities";
    return "overview";
  }, [location.pathname]);

  useEffect(() => {
    if (currentUser && userId) {
      const fetchData = async () => {
        const authToken = await currentUser.getIdToken();
        const data = await getUserInfoByUserId(userId, authToken);
        if (data) {
          console.log(data);
          setUser(data);
        }
      };

      fetchData();
    }
  }, [userId]);

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  const avatarUrl = findAvatarById(userId || "");

  return (
    <div className="relative bg-white dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full h-screen">
      <div className="bg-black w-full h-[300px] rounded-b-3xl"></div>
      <div className="overflow-x-auto -top-64 relative dark:bg-card-dark dark:text-text-dark bg-white p-5 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[600px] -mt-10">
        <div className="flex gap-2">
          <div className="user-profile-picture rounded-xl p-2 h-full w-1/4">
            <div className="avatar flex justify-start items-center align-middle">
              {avatarUrl ? (
                <div className="w-24 h-24 rounded-xl bg-gray-200 overflow-hidden shadow-lg shadow-gray-400">
                  <img
                    src={avatarUrl}
                    alt={`${user?.firstName + " " + user?.lastName}`}
                    className="w-40  h-40 rounded-full object-cover -mt-7"
                  />
                </div>
              ) : (
                <div className="text-sm">
                  {user?.firstName + " " + user?.lastName}
                </div>
              )}
            </div>
            <div className="user-info flex flex-col gap-1">
              <div className="user-name font-bold text-xl mt-3">
                {user?.firstName + " " + user?.lastName}
              </div>
              <div className="w-11/12 mx-auto mt-2 flex flex-col gap-1">
                <div className="location text-muted text-sm flex align-middle items-center gap-2">
                  <FontAwesomeIcon
                    className="w-[14px] text-center"
                    icon={faLocationDot}
                  />
                  Region 2
                </div>
                <div className="email text-muted text-sm flex align-middle items-center gap-2">
                  <FontAwesomeIcon
                    className="w-[14px] text-center"
                    icon={faEnvelope}
                  />
                  {user?.email}
                </div>
                <div className="organization text-muted text-sm flex align-middle items-center gap-2">
                  <FontAwesomeIcon
                    className="w-[14px] text-center"
                    icon={faBuilding}
                  />
                  {toTitleCase(user?.organization.name || "")}
                </div>
                <div className="organization text-muted text-sm flex align-middle items-center gap-2">
                  <FontAwesomeIcon
                    className="w-[14px] text-center"
                    icon={faBriefcase}
                  />
                  {user && user?.roles.length > 0
                    ? toTitleCase(
                        user.roles.reduce((maxRole, currentRole) =>
                          currentRole.id > maxRole.id ? currentRole : maxRole
                        ).name
                      )
                    : "No roles available"}
                </div>
              </div>
            </div>
          </div>
          <div className="user-profile-content rounded-xl w-full p-2 h-full">
            <div className="user-profile-nav flex justify-center gap-10 text-xs font-bold">
              <div
                className={`overview px-2 hover:bg-gray-100 ${
                  activeNavItem === "overview"
                    ? "bg-blue-200 text-blue-500"
                    : ""
                } cursor-pointer p-1 rounded-md`}
                onClick={() => navigate(`/profile/user/${userId}`)}
              >
                <div>Overview</div>
                <div></div>
              </div>
              <div
                className={`tasks px-2 flex items-center align-middle hover:bg-gray-100  ${
                  activeNavItem === "tasks"
                    ? "bg-blue-200 text-blue-500"
                    : ""
                } cursor-pointer p-1 rounded-md`}
                onClick={() => navigate(`/profile/user/${userId}/tasks`)}
              >
                <div>Tasks</div>
                {/* <div className="text-muted font-normal px-1">0</div> */}
              </div>
              <div
                className={`activities px-2 flex items-center align-middle hover:bg-gray-100  ${
                  activeNavItem === "activities"
                    ? "bg-blue-200 text-blue-500"
                    : ""
                } cursor-pointer p-1 rounded-md`}
                onClick={() => navigate(`/profile/user/${userId}/activities`)}
              >
                <div>Activities</div>
                {/* <div className="text-muted font-normal px-1">0</div> */}
              </div>
              <div
                className={`documents px-2 flex items-center align-middle hover:bg-gray-100  ${
                  activeNavItem === "documents"
                    ? "bg-blue-200 text-blue-500"
                    : ""
                } cursor-pointer p-1 rounded-md`}
                onClick={() => navigate(`/profile/user/${userId}/documents`)}
              >
                <div>Documents</div>
                {/* <div className="text-muted font-normal px-1">0</div> */}
              </div>
              <div
                className={`files px-2 flex items-center align-middle hover:bg-gray-100  ${
                  activeNavItem === "files"
                    ? "bg-blue-200 text-blue-500"
                    : ""
                } cursor-pointer p-1 rounded-md`}
                onClick={() => navigate(`/profile/user/${userId}/files`)}
              >
                <div>Files</div>
                {/* <div className="text-muted font-normal px-1">0</div> */}
              </div>
            </div>
            <div className="mt-5">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

// FolderItem.tsx
import React, { useState } from "react";
import {
  DriveItem,
  ViewMode,
} from "../../../../../interfaces/caseFileDrive/DriveFiles";
import { getTimeAgo } from "../../../../../utilities/dateUtils";
import { formatFileSize } from "../../../../../utilities/fileUtils";

interface FolderItemProps {
  folder: DriveItem;
  viewMode: ViewMode;
  onClick?: () => void;
  onRightClick?: (e: React.MouseEvent) => void;

  // Rename props
  renameTarget?: DriveItem | null;
  renameValue?: string;
  setRenameValue?: (val: string) => void;
  doRename?: (newName: string) => void;
}

const FolderItem: React.FC<FolderItemProps> = ({
  folder,
  viewMode,
  onClick,
  onRightClick,
  renameTarget,
  renameValue,
  setRenameValue,
  doRename,
}) => {
  const [showFullName, setShowFullName] = useState<boolean>(false);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    setShowFullName(true);
    const timeout = setTimeout(() => {}, 5000);
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setShowFullName(false);
  };

  // Are we renaming THIS folder?
  const isRenaming = renameTarget?.id === folder.id;

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (!doRename || !renameValue) return;
    if (e.key === "Enter") {
      doRename(renameValue);
    }
    // Optionally add Esc handling if you want to cancel rename
  }

  if (viewMode === "grid") {
    // For brevity, same pattern. For example only
    return (
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="file-item-grid flex flex-col justify-evenly p-2 m-2 border bg-white border-gray-200 rounded-md text-center text-ellipsis shadow-lg hover:shadow-none w-[125px] h-[125px] relative cursor-pointer"
        onClick={onClick}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onRightClick?.(e);
        }}
      >
        <div className="text-5xl">📁</div>
        {/* If renaming, show input */}
        <div>
          {isRenaming ? (
            <input
              className="text-xs mt-2 w-full border rounded p-1"
              value={renameValue}
              onChange={(e) => setRenameValue?.(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          ) : (
            <div className="text-xs mt-2 truncate line-clamp-2 text-start font-bold inter-font">
              {folder.name}
            </div>
          )}

          <div className="text-muted text-xs text-start">
            {formatFileSize(folder.size || 0)}
          </div>
        </div>

        {showFullName && !isRenaming && (
          <div
            className="absolute bg-gray-700 text-white text-xs rounded px-2 py-1 shadow-lg z-40"
            style={{
              top: "110%",
              left: "50%",
              transform: "translateX(-50%)",
              whiteSpace: "nowrap",
            }}
          >
            {folder.name}
          </div>
        )}
      </div>
    );
  }

  // TABLE VIEW
  return (
    <tr
      className="hover:bg-gray-100 cursor-pointer text-xs border-t inter-font"
      onClick={onClick}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onRightClick?.(e);
      }}
    >
      <td className="text-center text-base">📁</td>
      <td className="w-100">
        {isRenaming ? (
          <input
            className="border p-1 rounded text-xs w-[150px]"
            value={renameValue}
            onChange={(e) => setRenameValue?.(e.target.value)}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        ) : (
          folder.name
        )}
      </td>
      <td className="w-[100px]"></td>
      <td className="text-end">
        {folder.updateDate ? getTimeAgo(folder.updateDate) : ""}
      </td>
      <td className="text-center">{formatFileSize(folder.size || 0)}</td>
    </tr>
  );
};

export default FolderItem;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../firebase/AuthProvider";
import {
  getUserFileById,
  getUserFileMetadata,
} from "../../../services/fileServices/UserDriverService";
import { UserFile } from "../../../interfaces/userDrive/userFile";
import UserFilePDFViewer from "./pdfViewer/UserFilePDFViewer";
import FileViewer from "react-file-viewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { formatFileSize } from "../../../utilities/fileUtils";

const UserFileViewPage: React.FC = () => {
  // ... existing state and logic ...
  const [fileUrl, setFileUrl] = useState("");
  const [blobInferredMimeType, setBlobInferredMimeType] = useState<string>("");

  const { currentUser } = useAuth();
  const { fileId } = useParams();

  const [fileMetadata, setFileMetadata] = useState<UserFile>();
  const [isInfoPanelOpen, setIsInfoPanelOpen] = useState(true);
  const [scale, setScale] = useState(1.0);
  const [zoom, setZoom] = useState<number>(1.0);
  const [textContent, setTextContent] = useState<string>("");

  useEffect(() => {
    const fetchFile = async () => {
      if (!currentUser || !fileId) return;
      try {
        const authToken = await currentUser.getIdToken();
        const file: UserFile | null = await getUserFileMetadata(
          fileId,
          authToken
        );
        if (!file) return;

        setFileMetadata(file);

        // 2) Use "fileLoc" to fetch the blob; don't rely on fileUrl state yet
        const fileLoc = await getUserFileById(Number(fileId), authToken);
        if (!fileLoc) return;

        setFileUrl(fileLoc); // this updates the state for the viewer to use

        // Let's fetch with fileLoc (the actual URL):
        const response = await fetch(fileLoc);
        const blob = await response.blob();

        // 3) Infer the MIME type from extension if needed
        let inferredMimeType = file.mimeType || blob.type;
        if (inferredMimeType === "application/octet-stream") {
          const extension = file.name?.split(".").pop()?.toLowerCase();
          switch (extension) {
            case "pdf":
              inferredMimeType = "application/pdf";
              break;
            case "jpg":
            case "jpeg":
              inferredMimeType = "image/jpeg";
              break;
            case "png":
              inferredMimeType = "image/png";
              break;
            case "txt":
              inferredMimeType = "text/plain";
              break;
            case "doc":
            case "docx":
              inferredMimeType =
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
              break;
            case "xls":
            case "xlsx":
              inferredMimeType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              break;
            case "mp4":
            case "mov":
            case "webm":
            case "avi":
              inferredMimeType = `video/${extension}`;
              break;
            // ...
          }
        }
        setBlobInferredMimeType(inferredMimeType);

        // If it's text, read it:
        if (inferredMimeType === "text/plain") {
          const text = await response.text();
          setTextContent(text);
        }
      } catch (error) {
        console.error("Error fetching file:", error);
      }
    };

    fetchFile();
  }, [currentUser, fileId]);

  // 4) Render logic
  const renderViewer = () => {
    if (!fileUrl) return <div>Loading...</div>;

    // extension for fallback checks
    const extension = fileMetadata?.name?.split(".").pop()?.toLowerCase();

    // PDF
    if (blobInferredMimeType === "application/pdf" || extension === "pdf") {
      return <UserFilePDFViewer fileUrl={fileUrl} scale={scale} />;
    }

    // Images
    if (
      blobInferredMimeType.startsWith("image/") ||
      ["jpg", "jpeg", "png", "gif"].includes(extension || "")
    ) {
      return (
        <div>
          <div
            style={{
              transform: `scale(${zoom})`,
              transformOrigin: "center",
              display: "inline-block",
            }}
          >
            <img
              src={fileUrl}
              alt={fileMetadata?.name}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
          <div className="flex items-center justify-end gap-2 mt-2">
            <label>Zoom: </label>
            <input
              type="range"
              min="0.5"
              max="3.0"
              step="0.1"
              value={zoom}
              onChange={(e) => setZoom(parseFloat(e.target.value))}
            />
            <span>{zoom.toFixed(1)}x</span>
          </div>
        </div>
      );
    }

    // Plain text
    if (
      blobInferredMimeType === "text/plain" ||
      ["txt", "csv", "log"].includes(extension || "")
    ) {
      return (
        <pre className="whitespace-pre-wrap break-words bg-white p-4">
          {textContent}
        </pre>
      );
    }

    // Video
    if (
      blobInferredMimeType.startsWith("video/") ||
      ["mp4", "webm", "mov", "avi"].includes(extension || "")
    ) {
      return <video src={fileUrl} controls style={{ maxWidth: "100%" }} />;
    }

    // 6) Fallback: let react-file-viewer handle docx, etc.
    let fileType = extension || "unknown";
    if (
      blobInferredMimeType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      fileType = "docx";
    }
    // etc. for pptx, etc.

    return (
      <FileViewer
        fileType={fileType}
        filePath={fileUrl}
        errorComponent={() => <div>Error loading file</div>}
        onError={(err) => console.log("FileViewer error:", err)}
      />
    );
  };

  return (
    <div
      className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] 
    overflow-x-auto max-w-full h-screen overflow-hidden"
    >
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>
      <div className="-top-64 relative dark:bg-card-dark dark:text-text-dark 
                        bg-[#F7F9FF] rounded-3xl shadow-lg w-11/12 mx-auto 
                        min-h-[600px] -mt-10 overflow-hidden">
        <div className="relative dark:bg-card-dark bg-[#F7F9FF] p-5 rounded-3xl mx-auto min-h-[600px]">
          {/* Back button */}
          <div className="flex items-center align-middle text-sm mb-5">
            <div
              className="cursor-pointer rounded-xl p-2 bg-white shadow-md hover:shadow-none"
              onClick={() => window.history.back()}
            >
              {"< "}Back
            </div>
          </div>

          {/* Title */}
          <div className="text-2xl inter-font text-center my-5">
            {fileMetadata?.name}
          </div>

          {/* PDF zoom controls (only visible if PDF) */}
          {blobInferredMimeType === "application/pdf" && (
            <div className="zoomControls flex align-middle items-center gap-2 mb-5 justify-end me-5">
              <label htmlFor="scale">Zoom: </label>
              <input
                type="range"
                id="scale"
                min="0.5"
                max="2.0"
                step="0.1"
                value={scale}
                onChange={(e) => setScale(parseFloat(e.target.value))}
                className="accent-[#3dd598]"
              />
              <span>{scale.toFixed(1)}x</span>
            </div>
          )}

          {/* Main viewer + collapsible side panel */}
          <div className="flex gap-4">
            {/* File viewer area */}
            <div className="flex-grow flex items-center justify-center min-h-[400px] overflow-x-auto">
              {renderViewer()}
            </div>

            {/* Toggle Info Panel */}
            <button
              className="h-7 px-2 border bg-white -mr-5 z-50 rounded-full shadow-md self-start mt-2"
              onClick={() => setIsInfoPanelOpen(!isInfoPanelOpen)}
            >
              {isInfoPanelOpen ? (
                <FontAwesomeIcon icon={faCaretRight} />
              ) : (
                <FontAwesomeIcon icon={faCaretLeft} />
              )}
            </button>

            {isInfoPanelOpen && (
              <div className="min-w-[200px] bg-white dark:bg-gray-800 rounded-xl p-4 shadow-md transition-all duration-300 overflow-auto">
                <h3 className="text-lg font-semibold mb-2 inter-font text-text">File Info</h3>
                {fileMetadata && (
                  <ul className="text-sm space-y-2">
                    <li>
                      <strong>Name:</strong> {fileMetadata.name}
                    </li>
                    <li>
                      <strong>Type:</strong> {fileMetadata.mimeType}
                    </li>
                    <li>
                      <strong>Size:</strong> {formatFileSize(fileMetadata.size)}
                    </li>
                    <li>
                      <strong>Created:</strong>{" "}
                      {fileMetadata.createDate
                        ? new Date(fileMetadata.createDate).toLocaleString()
                        : "N/A"}
                    </li>
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFileViewPage;

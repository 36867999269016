import React from 'react';
import tinycolor from 'tinycolor2';

interface TagBadgeProps {
  name: string;
  color?: string | null; // can be null
}

const TagBadge: React.FC<TagBadgeProps> = ({ name, color }) => {
  // 1) Normalize color with fallback
  const fallbackColor = '#ccc';
  const baseColor = tinycolor(color ?? fallbackColor);

  // 2) Adjust background color to make it a decent "badge" background.
  //    - If the base color is dark, lighten it a bit.
  //    - If it's very light, darken it a bit.
  let bgColor = baseColor.clone();
  if (baseColor.isDark()) {
    bgColor = bgColor.lighten(20); 
  } else if (baseColor.getLuminance() > 0.85) {
    // If it’s super bright, darken it
    bgColor = bgColor.darken(15);
  }

  // 3) Adjust text color in the *opposite* direction to keep the same hue.
  //    - If background ended up dark-ish, lighten the text color more.
  //    - Otherwise, darken the text color more.
  let textColor = baseColor.clone();
  if (bgColor.isDark()) {
    textColor = textColor.lighten(60);
  } else {
    textColor = textColor.darken(60);
  }

  // OPTIONAL: If you want to ensure at least some minimal contrast ratio
  // (rather than pure “same hue”), you can do a final check here and
  // push the textColor a bit further if needed.

  const bgColorString = bgColor.toHexString();
  const textColorString = textColor.toHexString();

  return (
    <div
      style={{
        backgroundColor: bgColorString,
        color: textColorString,
      }}
      className="inline-block text-xs font-medium px-2.5 py-0.5 rounded border"
    >
      {name}
    </div>
  );
};

export default TagBadge;

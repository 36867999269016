// file: DriveYear.tsx
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Participant,
  ParticipantYear,
  DriveItem,
} from "../../../../../interfaces/caseFileDrive/DriveFiles";
import { getDriveData } from "../../../../../services/fileServices/FileService";
import { useAuth } from "../../../../../firebase/AuthProvider";

// Import the file-to-driveItems mapper
import DriveList from "../driveItems/DriveList";
import { mapFilesToDriveItems } from "../../../../../utilities/MapFunctions";
import { faGripVertical, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDriveContext } from "../../../../../contexts/DriveContext";
import DriveSearch from "../driveItems/DriveSearch";

const DriveYear: React.FC = () => {
  const { currentUser, currentRoles } = useAuth();
  const { participantId, year } = useParams();
  const navigate = useNavigate();

  const [participant, setParticipant] = useState<Participant | null>(null);
  const [yearData, setYearData] = useState<ParticipantYear | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(""); // for filtering
  const [items, setItems] = useState<DriveItem[]>([]); // For DriveList
  const { viewMode, setViewMode } = useDriveContext();
  const [loading, setLoading] = useState<boolean>(true);
  const hasHigherRoleThanSC = currentRoles?.some((role) => role.name !== "SC");
  const { employeeId } = useParams<{ employeeId?: string }>();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        if (currentUser) {
          const authToken = await currentUser.getIdToken();
          let data;
          if (employeeId && hasHigherRoleThanSC)
            data = await getDriveData(authToken, employeeId);
          else data = await getDriveData(authToken); // data is an array of Participant
          const foundParticipant = data.find(
            (p) => p.participantId.toString() === participantId
          );
          if (foundParticipant) {
            const foundYear = foundParticipant.years.find(
              (y) => y.year.toString() === year
            );
            setParticipant(foundParticipant);
            setYearData(foundYear ?? null);

            if (foundYear) {
              // Convert the year’s files to DriveItem[]
              const fileItems = mapFilesToDriveItems(foundYear.files);
              setItems(fileItems);
            } else {
              setItems([]);
            }
          } else {
            setParticipant(null);
            setYearData(null);
            setItems([]);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [participantId, year, currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!participant || !yearData) {
    return (
      <div>
        <p>Year data not found.</p>
        <button
          className="border rounded-lg p-1 shadow-sm hover:shadow-none mb-2 bg-white"
          onClick={() => navigate(`/drive/caseFileDrive/${employeeId ? "employee/" + employeeId + "/" : "" }participant/${participantId}`)}
        >
          {"<"} Back
        </button>
      </div>
    );
  }

  // Filter items by searchTerm (case-insensitive by name)
  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Called when user clicks a file
  const handleItemClick = (item: DriveItem) => {
    if (item.isFolder) {
    } else {
      // It's a file
      navigate(`/view/file/${item.id}`);
      // Possibly open a preview or navigate somewhere else
    }
  };

  return (
    <div>
      <div className="flex justify-between items-start align-middle">
        <button
          className="border rounded-lg p-1 shadow-sm hover:shadow-none mb-2 text-xs bg-white"
          onClick={() => navigate(`/drive/caseFileDrive/${employeeId ? "employee/" + employeeId + "/" : "" }participant/${participantId}`)}
        >
          {"<"} Back
        </button>
        {/* Toggle for grid/table */}
        <div className="flex flex-col">
          <div className="flex justify-end">
            <DriveSearch
              onSearch={(query) => setSearchTerm(query)}
              placeholder="Search files..."
            />
          </div>
          <div className="flex gap-2 mb-2 justify-end">
            Display:
            <button
              className={
                viewMode === "grid"
                  ? "bg-[#3DD598] text-white w-7 h-7"
                  : "bg-gray-200 w-7 h-7"
              }
              onClick={() => setViewMode("grid")}
            >
              <FontAwesomeIcon icon={faGripVertical} />
            </button>
            <button
              className={
                viewMode === "table"
                  ? "bg-[#3DD598] text-white w-7 h-7"
                  : "bg-gray-200 w-7 h-7"
              }
              onClick={() => setViewMode("table")}
            >
              <FontAwesomeIcon icon={faList} />
            </button>
          </div>
        </div>
      </div>
      <h2 className="text-text inter-font font-bold text-xl mb-3">
        {participant.participantName} / {yearData.year}
      </h2>
      {/* Render the files in this year */}
      <DriveList
        items={filteredItems}
        viewMode={viewMode}
        onItemClick={handleItemClick}
        onRightClickItem={() => {}}
      />
    </div>
  );
};

export default DriveYear;

import React, { useEffect, useState } from "react";
import Task from "../../../interfaces/task/Task";
import TaskActivity from "./TaskActivity";
import TaskAttachements from "./taskAttachments/TaskAttachments";
import Status from "../../../interfaces/task/Status";
import RequiredTaskActions from "./RequiredTaskActions/RequiredTaskActions";
import AssigneeWithTooltip from "./AssigneeWithTooltip";
import { formatDateToMMDDYYYY } from "../../../utilities/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faPaperclip } from "@fortawesome/free-solid-svg-icons";
// import { approveTask } from "../../../services/taskServices/TaskWorkflowServices";
import { useAuth } from "../../../firebase/AuthProvider";
import TaskWorkflowDiagram from "./TaskWorkflowDiagram/TaskWorkflowDiagram";
import TextEditor from "../textEditor/TextEditor";
import { getCanUserDeleteTaskFile } from "../../../services/fileServices/FileService";
import { useNavigate } from "react-router-dom";
import TagBadge from "../../ui/Tag/TagBadge";

interface TaskProps {
  task: Task;
  statusOptions: Status[] | null;
}

const TaskComponent: React.FC<TaskProps> = ({ task, statusOptions }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [canUserDeleteFile, setCanUserDeleteFile] = useState<boolean>(false);

  useEffect(() => {
    if (reload) {
      setLoading(true);
      setLoading(false);
    }

    const fetchCanUserDeleteTaskFiles = async () => {
      if (currentUser) {
        const authToken = await currentUser.getIdToken();
        const response = await getCanUserDeleteTaskFile(authToken, task.id);
        setCanUserDeleteFile(response);
      }
    };
    fetchCanUserDeleteTaskFiles();
  }, [reload]);

  const handleReload = () => {
    setReload(!reload);
  };

  const handleCopyClick = async () => {
    try {
      const url = window.location.href;
      await navigator.clipboard.writeText(url);
      setCopied(true);
    } catch (err) {
      console.error("Failed to copy URL");
    }
  };

  return (
    <div className="w-full">
      <div className="flex gap-2 items-center">
        <div
          onClick={handleCopyClick}
          className="text-muted text-xs italic cursor-pointer hover:underline flex gap-2 overflow-hidden"
        >
          {" "}
          {task.organization.name.toUpperCase()}-{task.id}
          <div className="relative group-hover:translate-x-0 ease-in-out">
            <FontAwesomeIcon icon={faPaperclip} />
          </div>
        </div>
        {copied && (
          <div className="text-muted text-xs bg-background p-1 rounded-lg">
            Link copied to clipboard!
          </div>
        )}
      </div>
      <div>
        <h1 className="my-5 text-4xl inter-font text-text text-center">
          {task.title}
        </h1>
      </div>
      <div className="text-text dark:text-text-dark">
        <div className="flex justify-center w-full">
          {reload && (
            <TaskWorkflowDiagram
              taskId={task.id}
              isReady={task.isReady}
              status={task.status.name}
              setReload={setReload}
              reload={reload}
            />
          )}
          {!reload && (
            <TaskWorkflowDiagram
              taskId={task.id}
              isReady={task.isReady}
              status={task.status.name}
              setReload={setReload}
              reload={reload}
            />
          )}
        </div>
        <div className="flex flex-wrap">
          <div className="flex justify-between flex-col text-sm w-1/2">
            <p className="text-muted">Description:</p>{" "}
            <p className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
              {task.description}
            </p>
          </div>
          <div className="flex flex-col text-sm w-1/2">
            <p className="text-muted me-2">Due Date: </p>
            <p className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
              {formatDateToMMDDYYYY(task.endDate)}
            </p>
          </div>
          <div className="flex flex-col text-sm my-2 w-1/2">
            <p className="text-muted me-2">Tags:</p>{" "}
            {/* <p className="green-badge text-end">{task.taskOrganization.type}</p>
          <p className="default-badge text-end">{task.taskType.type}</p> */}
            <div className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg flex flex-wrap gap-2">
              <TagBadge name={task.subType.type} color={"#FF3C58"} />
              {task.tags.map((tag, index) => (
                <div key={index}>
                  <TagBadge name={tag.name.toUpperCase()} color={tag.color} />
                </div>
              ))}
            </div>
          </div>
          {task.participant ? (
            <div className="flex flex-col text-sm py-2 w-1/2 justify-start">
              <div className="text-muted me-2 my-auto align-center">
                Participant:
              </div>
              <p
                className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg text-blue-500 cursor-pointer"
                onClick={() =>
                  navigate(`/edit/participant/${task.participant.id}`)
                }
              >
                {task.participant.firstName + " " + task.participant.lastName}
              </p>
            </div>
          ) : (
            <div className="flex flex-col text-sm align-center py-2 w-1/2">
              <div className="text-muted me-2 my-auto align-center">
                Participant:
              </div>
              <p className="bg-background m-2 dark:bg-background-dark p-2 rounded-lg">
                N/A
              </p>
            </div>
          )}
          <div className="flex flex-col text-sm align-center py-2 w-1/2 ps-2 gap-2">
            <p className="text-muted me-2 align-center">Assignee:</p>
            <div
              className="flex items-center align-middle relative"
              onClick={() =>
                navigate(
                  `/profile/user/${
                    task.assignees.filter(
                      (user) => !user.isReporter && !user.isCoAssignee
                    )[0].user.id
                  }`
                )
              }
            >
              <AssigneeWithTooltip
                assignee={
                  task.assignees.filter(
                    (user) => !user.isReporter && !user.isCoAssignee
                  )[0].user
                }
              />
            </div>
          </div>
          {task.assignees.filter((user) => user.isCoAssignee)[0]?.user ? (
            <div className="flex flex-col text-sm py-2 w-1/2 gap-2">
              <p className="text-muted me-2 align-center">Co-Assignee:</p>
              <div className="flex items-center align-middle relative"
                onClick={() => navigate(`/profile/user/${task.assignees.filter((user) => user.isCoAssignee)[0]?.user.id}`
                )} >
                <AssigneeWithTooltip
                  assignee={
                    task.assignees.filter((user) => user.isCoAssignee)[0]?.user
                  }
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="flex flex-col text-sm py-2 w-1/2 gap-2">
            <p className="text-muted me-2 align-center">Reporter:</p>
            <div className="flex items-center align-middle relative"
              onClick={() => navigate(`/profile/user/${task.assignees.filter((user) => user.isReporter)[0].user.id}`)}
            >
              <AssigneeWithTooltip
                assignee={
                  task.assignees.filter((user) => user.isReporter)[0]?.user
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div></div>
        <div>
          {task.information && (
            <div className="group flex relative">
              <span className="text-text dark:text-text-dark text-2xl bg-transparent px-2 py-1 ms-auto">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              <span className="group-hover:opacity-100 group-hover:z-50 -z-50 transition-opacity bg-transparent px-1 text-sm rounded-md absolute -translate-x-2/4 opacity-0 m-4 p-5 min-w-[500px]">
                <TextEditor
                  text={task.information ? task.information : ""}
                  setText={() => {}}
                  displayToolbar={false}
                />
              </span>
            </div>
          )}
        </div>
      </div>
      <TaskAttachements
        taskId={task.id}
        refreshTaskActivity={handleReload}
        canUserDeleteFile={canUserDeleteFile}
      />
      <RequiredTaskActions taskId={task.id} />
      <TaskActivity taskId={task.id} />
    </div>
  );
};

export default TaskComponent;

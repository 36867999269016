import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAuth } from "../../../../firebase/AuthProvider";
import NewTemplateTaskAction from "../../../../interfaces/templateTask/NewTemplateTaskAction";
import ActionType from "../../../../interfaces/task/taskActions/ActionType";
import ActionSubType from "../../../../interfaces/task/taskActions/ActionSubType";
import {
  getTaskActionSubTypes,
  getTaskActionTypes,
} from "../../../../services/taskServices/TaskActionService";
import Banner from "../../../ui/Banner/Banner";
import { publishTemplateTaskAction } from "../../../../services/templateService/TemplateTaskActionService";

interface AddTaskActionProps {
  taskId: number | undefined;
  reload: () => void;
}

interface OptionType {
  value: string;
  label: string;
}

const AddTaskAction: React.FC<AddTaskActionProps> = ({
  taskId,
  reload,
}) => {
  const { currentUser } = useAuth();

  const [actionTypeOptions, setActionTypeOptions] = useState<OptionType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [actionSubTypeOptions, setActionSubTypeOptions] = useState<
    OptionType[]
  >([]);

  const [actionTypes, setActionTypes] = useState<ActionType[] | null>();
  const [actionSubTypes, setActionSubTypes] = useState<
    ActionSubType[] | null
  >();
  const [newTask, setNewTask] = useState<NewTemplateTaskAction>({
    taskId: "",
    description: "",
    actionTypeId: "",
    actionSubTypeId: "",
    estimated_ttc: "",
  });

  useEffect(() => {
    const fetchLkValues = async () => {
      if (currentUser !== null) {
        const authToken: string = await currentUser.getIdToken();
        const fetchedActionTypes: ActionType[] | null =
          await getTaskActionTypes(authToken);
        const fetchedActionSubTypes: ActionSubType[] | null =
          await getTaskActionSubTypes(authToken);
        setActionTypes(fetchedActionTypes);
        setActionSubTypes(fetchedActionSubTypes);
      }
    };

    if (actionTypes && actionSubTypes) {
      const formattedActionTypes = actionTypes.map((actionType) => ({
        value: actionType.id.toString(),
        label: actionType.actionTypeName,
      }));
      const formattedActionSubTypes = actionSubTypes.map((actionSubType) => ({
        value: actionSubType.id.toString(),
        label: actionSubType.actionSubTypeName,
      }));

      setActionTypeOptions(formattedActionTypes);
      setActionSubTypeOptions(formattedActionSubTypes);
    } else {
      fetchLkValues();
    }
  }, [actionTypes, actionSubTypes]);

  const handleActionTypeChange = (selectedOption: SingleValue<OptionType>) => {
    const actionTypeId = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, actionTypeId });
  };

  const handleActionSubTypeChange = (
    selectedOption: SingleValue<OptionType>
  ) => {
    const actionSubTypeId = selectedOption ? selectedOption.value : "";
    setNewTask({ ...newTask, actionSubTypeId });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setNewTask({ ...newTask, [name]: value });
  };

  const addTemplateTaskAction = () => {
    // Verify that NewTemplateTaskAction filled out to completion
    console.log("Attempting to add");
    if (
      newTask.description !== "" &&
      newTask.actionTypeId !== "" &&
      newTask.actionSubTypeId !== ""
    ) {
      submitTemplateTaskActions();
      setNewTask({
        taskId: "",
        description: "",
        actionTypeId: newTask.actionTypeId,
        actionSubTypeId: newTask.actionSubTypeId,
        estimated_ttc: "",
      });
    } else {
      console.log("setting err message");
      setWarningMessage(
        "Description, Action Type, and Action Sub Type are all required fields!"
      );
    }
  };

  const dismissErrorBanner = () => {
    setErrorMessage("");
  };
  const dismissWarningBanner = () => {
    setWarningMessage("");
  };

  const submitTemplateTaskActions = async () => {
    if (taskId !== undefined && currentUser !== null) {
        try {
          const authToken: string = await currentUser.getIdToken();
          // Create an array of promises
          publishTemplateTaskAction(newTask, taskId, authToken)
            .then((data: number | null) => {
              reload();
            })

          // Wait for all promises to resolve
          // Only executed if all promises resolve successfully
        } catch (error) {
          setErrorMessage(
            "Unable to add required task actions to this template task, consider contact IT if this issue persists."
          );
          console.error("An error occurred:", error);
          // Handle any errors here (e.g., show an error message to the user)
        }
    }
  };

  return (
    <div>
      <div className="mx-auto block bg-card dark:bg-card-dark mt-10 rounded-lg shadow-xl p-10 border">
        <div className="mb-5">
          {errorMessage !== "" && (
            <Banner
              message={errorMessage}
              toggleBanner={dismissErrorBanner}
              dismissBanner={dismissErrorBanner}
              status="error"
            />
          )}
        </div>
        <div className="mb-5">
          {warningMessage !== "" && (
            <Banner
              message={errorMessage}
              toggleBanner={dismissWarningBanner}
              dismissBanner={dismissWarningBanner}
              status="warning"
            />
          )}
        </div>
        <div className="font-bold text-primary">
          Feeling a little, "Schedule-y" today?{" "}
        </div>
        <div className="text-sm text-muted dark:text-muted-dark mt-3">
          Add some sub-tasks to help spice up the daily grind!
        </div>
        <div>
          <div className="my-4">
            <label
              htmlFor="description"
              className="block text-text dark:text-text-dark text-sm font-bold mb-2"
            >
              Description:
            </label>
            <textarea
              id="description"
              name="description"
              placeholder="A brief description of what the action entails..."
              value={newTask.description}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-text dark:text-text-dark leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="my-4">
            <label
              htmlFor="actionType"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Task Type:
            </label>
            <Select
              id="actionType"
              name="actionType"
              value={actionTypeOptions.find(
                (option) => option.value === newTask.actionTypeId
              )}
              onChange={handleActionTypeChange}
              options={actionTypeOptions}
              className="your-custom-class"
              classNamePrefix="select"
            />
          </div>
          <div className="my-4">
            <label
              htmlFor="actionSubType"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Task Sub-Type:
            </label>
            <Select
              id="actionSubType"
              name="actionSubType"
              value={actionSubTypeOptions.find(
                (option) => option.value === newTask.actionSubTypeId
              )}
              onChange={handleActionSubTypeChange}
              options={actionSubTypeOptions}
              className="your-custom-class"
              classNamePrefix="select"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="estimated_ttc"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Estimated Time To Completion (TTC) In Minutes:
          </label>
          <input
            id="estimated_ttc"
            type="number"
            className="border border-gray-300 rounded-md p-2 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="Enter a number"
            min="0"
          />
        </div>
        <div className="mt-5 flex gap-2 justify-end">
        <button className="bg-brandSecondary text-white rounded-lg ms-auto p-2 min-w-[75px]"
            onClick={addTemplateTaskAction} // Make sure to update this to include the actions
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTaskAction;

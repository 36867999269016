import { CreateUserFolderRequest } from "../../interfaces/userDrive/createUserFolderRequest";
import UploadUserFileResponse from "../../interfaces/userDrive/uploadUserFileResponse";
import { UserFile } from "../../interfaces/userDrive/userFile";
import { UserFolderResponse } from "../../interfaces/userDrive/userFolderResponse";

export async function getFolderContents(
  authToken: string,
  folderId?: number
): Promise<UserFolderResponse | null> {
  const folderIdSegment = folderId ? `${folderId}/` : "";
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/drive/user/folder/${folderIdSegment}contents`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const data: UserFolderResponse = await response.json();

    return data;
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function createFolder(
  authToken: string,
  request: CreateUserFolderRequest
): Promise<{ folderId: number }> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/drive/user/folder`;

  const response = await fetch(apiUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error("Failed to create folder");
  }

  const data = await response.json();
  return data;
}

export async function removeFolder(authToken: string, folderId: string): Promise<string> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/folders/${folderId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Try to parse the response body for the error message
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to delete folder");
      return errorData.message
    }

    return response.text();
  } catch (error:any) {
    throw error; // Re-throw the error if needed for further handling
  }
}

export async function removeFile(authToken: string, fileId: string): Promise<string> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/${fileId}`;

  try {
    const response = await fetch(apiUrl, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    if (!response.ok) {
      // Try to parse the response body for the error message
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to delete file");
    }

    return response.text();
  } catch (error:any) {
    throw error; // Re-throw the error if needed for further handling
  }
}



export const uploadFile = async (
  authToken: string,
  formData: FormData
): Promise<UploadUserFileResponse> => {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/drive/user`;

  const response = await fetch(apiUrl, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Failed to upload file");
  }

  const data = await response.json();
  return data;
};

export async function getUserFileById(
  id: number,
  authToken: string
): Promise<string | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/drive/user/download/${id}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function downloadUserFile(
  id: number,
  authToken: string
): Promise<Blob | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/drive/user/download/${id}`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const blob = await response.blob();
    return blob;
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function getUserFileMetadata(
  id: string,
  authToken: string
): Promise<UserFile | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/drive/user/download/${id}/metadata`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const data: UserFile = await response.json();
    return data;
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}

export async function renameFolder(
  folderId: string,
  newName: string,
  authToken: string
) {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/folders/${folderId}`;

  const req = {
    Name: newName,
  };

  const response = await fetch(apiUrl, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });

  if (!response.ok) {
    throw new Error("Failed to upload file");
  }

  const data = await response.json();
  return data;
}

export async function renameFile(
  fileId: string,
  newName: string,
  authToken: string
) {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/rename/${fileId}`;

  const req = {
    Name: newName,
  };

  const response = await fetch(apiUrl, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });

  if (!response.ok) {
    throw new Error("Failed to upload file");
  }

  const data = await response.json();
  return data;
}

export async function getTotalUserDriveSize(
  authToken: string, 
  employeeId?: string
): Promise<number | null> {
  const apiUrl: string = `${process.env.REACT_APP_API_BASE_URL}/file/userDrive/files/size`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    let size = await response.text();
    return Number(size);
  } catch (err) {
    console.error("Error fetching files:", err);
    return null;
  }
}
// file: CaseFileDrive.tsx
import React, { useEffect, useState } from "react";
import {
  DriveItem,
  Participant,
} from "../../../../../interfaces/caseFileDrive/DriveFiles";
import { getDriveData } from "../../../../../services/fileServices/FileService";
import { useAuth } from "../../../../../firebase/AuthProvider";
import DriveList from "../driveItems/DriveList";
import { mapParticipantsToDriveItems } from "../../../../../utilities/MapFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretRight,
  faGripVertical,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import { useDriveContext } from "../../../../../contexts/DriveContext";

// Import our new search component
import DriveSearch from "../driveItems/DriveSearch";
import { getTeamBySupervisor } from "../../../../../services/userServices/TeamService";
import { TeamUser } from "../../../../../interfaces/team/TeamUser";
import UserFolderCard from "../../../userComponents/UserFolderCard";

const CaseFileDrive: React.FC = () => {
  const { currentUser, currentRoles } = useAuth();
  const navigate = useNavigate();
  const { viewMode, setViewMode } = useDriveContext();

  const [participants, setParticipants] = useState<Participant[] | null>(null);
  const [items, setItems] = useState<DriveItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>(""); // for filtering
  const [loading, setLoading] = useState<boolean>(true);
  const hasHigherRoleThanSC = currentRoles?.some((role) => role.name !== "SC");
  const { employeeId } = useParams<{ employeeId?: string }>();
  const [employees, setEmployees] = useState<TeamUser[]>();
  const [hoveredEmployeeId, setHoveredEmployeeId] = useState<string | null>(
    null
  );
  const [employeeDriveCollapsed, setEmployeeDriveCollapsed] =
    useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    async function fetchData() {
      try {
        if (currentUser) {
          const uid = await currentUser.getIdToken();
          let data;
          if (employeeId && hasHigherRoleThanSC) {
            console.log("fetching admin data for employee", employeeId);
            data = await getDriveData(uid, employeeId);
          } else {
            console.log("fetching default drive data");
            data = await getDriveData(uid);
          }
          // data is an array of Participant
          setParticipants(data);

          const driveItems = mapParticipantsToDriveItems(data);
          setItems(driveItems);
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    }

    async function fetchEmployees() {
      try {
        console.log("hasHigherRoleThanSC: ", hasHigherRoleThanSC);
        if (currentUser && hasHigherRoleThanSC) {
          const authToken = await currentUser.getIdToken();
          const data = await getTeamBySupervisor(authToken);
          if (data) setEmployees(data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchEmployees();
    fetchData();

    setLoading(false);
  }, [currentUser, currentRoles, employeeId]);

  if (loading) return <div>Loading...</div>;

  if (!participants || participants.length === 0) {
    return <div>No participants found.</div>;
  }

  // Filter items by searchTerm (case-insensitive by name)
  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Called when user clicks a folder/file row or icon
  const handleItemClick = (item: DriveItem) => {
    if (item.isFolder) navigate(`participant/${item.id}`);
  };

  return (
    <div className="overflow-y-auto">
      <div className="flex-col flex justify-end">
        {/* SEARCH BOX */}
        <div className="flex justify-end">
          <DriveSearch
            onSearch={(query) => setSearchTerm(query)}
            placeholder="Search folders..."
          />
        </div>
        {/* Toggle for grid/table */}
        <div className="flex gap-2 mb-2  justify-end">
          Display:
          <button
            className={
              viewMode === "grid"
                ? "bg-[#3DD598] text-white w-7 h-7"
                : "bg-gray-200 w-7 h-7"
            }
            onClick={() => setViewMode("grid")}
          >
            <FontAwesomeIcon icon={faGripVertical} />
          </button>
          <button
            className={
              viewMode === "table"
                ? "bg-[#3DD598] text-white w-7 h-7"
                : "bg-gray-200 w-7 h-7"
            }
            onClick={() => setViewMode("table")}
          >
            <FontAwesomeIcon icon={faList} />
          </button>
        </div>
      </div>
      {hasHigherRoleThanSC && (
        <div
        //   onClick={() => setEmployeeDriveCollapsed(!employeeDriveCollapsed)}
          className={`px-5 font-bold inter-font rounded-lg mx-2 mb-2 border text-lg ${
            !employeeDriveCollapsed ? "bg-white" : "bg-gray-200"
          }`}
        >
          <div className="flex justify-between items-center align-middle p-2 cursor-pointer">
            <h2 className="text-text inter-font font-bold text-xl">
              Employee Drives:
            </h2>
            <div>
              {employeeDriveCollapsed ? (
                <FontAwesomeIcon icon={faCaretDown} />
              ) : (
                <FontAwesomeIcon icon={faCaretRight} />
              )}
            </div>
          </div>
          {employeeDriveCollapsed && hasHigherRoleThanSC && (
            <div className="flex m-1 gap-2">
              {employees?.map((employee: TeamUser) => (
                <div
                  key={employee.id}
                  className="relative "
                  onMouseEnter={() => setHoveredEmployeeId(employee.id)}
                  onMouseLeave={() => setHoveredEmployeeId(null)}
                >
                  {/* Display the employee name */}
                  <div
                    className={`text-sm cursor-pointer p-1 rounded-lg hover:shadow-none ${
                      employeeId === employee.id
                        ? "bg-gray-500 shadow-none text-white"
                        : "bg-white shadow-md"
                    }`}
                    onClick={() =>
                      navigate(`/drive/caseFileDrive/employee/${employee.id}`)
                    }
                  >
                    {employee.firstName + " " + employee.lastName}
                  </div>

                  {/* Show UserFolderCard only when hovering */}
                  {hoveredEmployeeId === employee.id && (
                    <div className="absolute z-10">
                      <div className="mt-1">
                        <UserFolderCard userId={employee.id} />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="mt-5">
        <h2 className="text-text inter-font font-bold text-xl mb-3">
          Participants:
        </h2>
        {/* Render the filtered drive items in the chosen view mode */}
        <DriveList
          items={filteredItems}
          viewMode={viewMode}
          onItemClick={handleItemClick}
          onRightClickItem={() => {}}
        />
      </div>
    </div>
  );
};

export default CaseFileDrive;

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Select, { MultiValue, ActionMeta } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faX } from "@fortawesome/free-solid-svg-icons";

import TasksPageTable from "./TasksPageTable";
import Pagination from "../../ui/Pagination/Pagination";

import TaskList from "../../../interfaces/task/TaskList";
import { User } from "../../../interfaces/User";
import Participant from "../../../interfaces/Participant";
import Status from "../../../interfaces/task/Status";
import Avatar from "../../../interfaces/avatar/Avatar";

interface TasksPageProps {
  tasks: TaskList[];
  assignees: User[];
  participants: Participant[];
  statuses: Status[];
  avatars: Avatar[];
  currentPage: number;
  pageSize: number;
  totalTasks: number;
  onPageChange: (n: number) => void;
  isLoading: boolean;
  onFilterChange: (filters: {
    status?: string[];
    type?: string[];
    date?: string[];
    assignees?: string[];
    participants?: string[];
  }) => void;
}

interface OptionType {
  value: string;
  label: string;
}

const TasksPageNew: React.FC<TasksPageProps> = ({
  tasks,
  assignees,
  participants,
  statuses,
  avatars,
  currentPage,
  totalTasks,
  pageSize,
  onPageChange,
  onFilterChange,
  isLoading,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  // ----------------------------------------------------------------
  // Local states for each filter dropdown
  // ----------------------------------------------------------------
  const [assigneeFilter, setAssigneeFilter] = useState<MultiValue<OptionType>>([]);
  const [participantFilter, setParticipantFilter] = useState<MultiValue<OptionType>>([]);
  const [typeFilter, setTypeFilter] = useState<MultiValue<OptionType>>([]);
  const [statusFilter, setStatusFilter] = useState<MultiValue<OptionType>>([]);
  const [dateFilter, setDateFilter] = useState<MultiValue<OptionType>>([]);
  const [toggleFilterForm, setToggleFilterForm] = useState(false);

  // We'll use this to prevent the second effect from firing at least once:
  const [didInitialize, setDidInitialize] = useState(false);

  // ----------------------------------------------------------------
  // Build react-select options via useMemo
  // ----------------------------------------------------------------
  const assigneeOptions: OptionType[] = useMemo(
    () =>
      assignees.map((assignee) => ({
        value: assignee.id.toString(),
        label: assignee.displayName,
      })),
    [assignees]
  );

  const participantOptions: OptionType[] = useMemo(
    () => [
      { value: "none", label: "None" }, // For tasks with no participant
      ...participants.map((p) => ({
        value: p.id.toString(),
        label: `${p.firstName} ${p.lastName}`,
      })),
    ],
    [participants]
  );

  const statusOptions: OptionType[] = useMemo(
    () =>
      statuses.map((status) => ({
        value: status.name,
        label: status.name,
      })),
    [statuses]
  );

  const dateOptions: OptionType[] = useMemo(
    () => [
      { value: "overdue", label: "Overdue" },
      { value: "today", label: "Today" },
      { value: "week", label: "This Week" },
      { value: "month", label: "This Month" },
    ],
    []
  );

  const typeOptions: OptionType[] = useMemo(
    () => [
      { value: "interim", label: "Interim" },
      { value: "monthly", label: "Monthly" },
      { value: "quarterly", label: "Quarterly" },
      { value: "annual", label: "Annual" },
    ],
    []
  );

  // ----------------------------------------------------------------
  // Helpers to compare MultiValue<OptionType>
  // ----------------------------------------------------------------
  function areMultiValueOptionsEqual(
    arr1: MultiValue<OptionType>,
    arr2: MultiValue<OptionType>
  ): boolean {
    if (arr1.length !== arr2.length) return false;
    const ids1 = arr1.map((o) => o.value).sort();
    const ids2 = arr2.map((o) => o.value).sort();
    return ids1.every((val, idx) => val === ids2[idx]);
  }

  // ----------------------------------------------------------------
  // Parse URL => build filter arrays
  // ----------------------------------------------------------------
  function parseParamsFromUrl(): {
    assigneeParsed: MultiValue<OptionType>;
    participantParsed: MultiValue<OptionType>;
    typeParsed: MultiValue<OptionType>;
    statusParsed: MultiValue<OptionType>;
    dateParsed: MultiValue<OptionType>;
  } {
    const params = new URLSearchParams(location.search);

    const assignee = params.get("assignee");
    const participant = params.get("participant");
    const type = params.get("type");
    const status = params.get("status");
    const date = params.get("date");

    const assigneeParsed = assignee
      ? assigneeOptions.filter((opt) => assignee.split(",").includes(opt.value))
      : [];
    const participantParsed = participant
      ? participantOptions.filter((opt) =>
          participant.split(",").includes(opt.value)
        )
      : [];
    const typeParsed = type
      ? typeOptions.filter((opt) => type.split(",").includes(opt.value))
      : [];
    const statusParsed = status
      ? statusOptions.filter((opt) => status.split(",").includes(opt.value))
      : [];
    const dateParsed = date
      ? dateOptions.filter((opt) => date.split(",").includes(opt.value))
      : [];

    return {
      assigneeParsed,
      participantParsed,
      typeParsed,
      statusParsed,
      dateParsed,
    };
  }

  // ----------------------------------------------------------------
  // Effect #1: On location.search changes => parse the URL
  //  - Update filter states if changed
  //  - Mark didInitialize=true so we know we've loaded once
  // ----------------------------------------------------------------
  useEffect(() => {
    const {
      assigneeParsed,
      participantParsed,
      typeParsed,
      statusParsed,
      dateParsed,
    } = parseParamsFromUrl();

    let changed = false;

    if (!areMultiValueOptionsEqual(assigneeFilter, assigneeParsed)) {
      setAssigneeFilter(assigneeParsed);
      changed = true;
    }
    if (!areMultiValueOptionsEqual(participantFilter, participantParsed)) {
      setParticipantFilter(participantParsed);
      changed = true;
    }
    if (!areMultiValueOptionsEqual(typeFilter, typeParsed)) {
      setTypeFilter(typeParsed);
      changed = true;
    }
    if (!areMultiValueOptionsEqual(statusFilter, statusParsed)) {
      setStatusFilter(statusParsed);
      changed = true;
    }
    if (!areMultiValueOptionsEqual(dateFilter, dateParsed)) {
      setDateFilter(dateParsed);
      changed = true;
    }

    // If anything changed, notify parent so it can fetch tasks
    if (changed) {
      onFilterChange({
        assignees: assigneeParsed.map((it) => it.value),
        participants: participantParsed.map((it) => it.value),
        type: typeParsed.map((it) => it.value),
        status: statusParsed.map((it) => it.value),
        date: dateParsed.map((it) => it.value),
      });
    }

    // Mark that we've done at least one parse
    if (!didInitialize) {
      setDidInitialize(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // ----------------------------------------------------------------
  // Effect #2: On local filter states change => update the URL
  //  *BUT* only if we have finished the initial parse (didInitialize)
  // ----------------------------------------------------------------
  useEffect(() => {
    // Skip on the very first render (before we parse from URL)
    if (!didInitialize) return;

    const params = new URLSearchParams();

    if (assigneeFilter.length > 0) {
      params.set(
        "assignee",
        assigneeFilter.map((a) => a.value).join(",")
      );
    }
    if (participantFilter.length > 0) {
      params.set(
        "participant",
        participantFilter.map((p) => p.value).join(",")
      );
    }
    if (typeFilter.length > 0) {
      params.set(
        "type",
        typeFilter.map((t) => t.value).join(",")
      );
    }
    if (statusFilter.length > 0) {
      params.set(
        "status",
        statusFilter.map((s) => s.value).join(",")
      );
    }
    if (dateFilter.length > 0) {
      params.set(
        "date",
        dateFilter.map((d) => d.value).join(",")
      );
    }

    const newQuery = params.toString();
    const oldQuery = new URLSearchParams(location.search).toString();

    if (newQuery !== oldQuery) {
      navigate(`${location.pathname}?${newQuery}`, { replace: true });
    }

    // Also notify parent, if you want the parent to fetch again on local changes.
    onFilterChange({
      assignees: assigneeFilter.map((it) => it.value),
      participants: participantFilter.map((it) => it.value),
      type: typeFilter.map((it) => it.value),
      status: statusFilter.map((it) => it.value),
      date: dateFilter.map((it) => it.value),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assigneeFilter,
    participantFilter,
    typeFilter,
    statusFilter,
    dateFilter,
    didInitialize, // Also watch for didInitialize changing from false->true
  ]);

  // ----------------------------------------------------------------
  // Handlers for changing local filter states
  // ----------------------------------------------------------------
  const handleAssigneeFilterChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setAssigneeFilter(newValue);
  };
  const handleParticipantFilterChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setParticipantFilter(newValue);
  };
  const handleTypeFilterChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setTypeFilter(newValue);
  };
  const handleStatusFilterChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setStatusFilter(newValue);
  };
  const handleDateFilterChange = (
    newValue: MultiValue<OptionType>,
    _actionMeta: ActionMeta<OptionType>
  ) => {
    setDateFilter(newValue);
  };

  // ----------------------------------------------------------------
  // Toggle the filter form
  // ----------------------------------------------------------------
  const handleToggleFilterForm = () => {
    setToggleFilterForm(!toggleFilterForm);
  };

  // ----------------------------------------------------------------
  // Clear all filters
  // ----------------------------------------------------------------
  const clearFilters = () => {
    setAssigneeFilter([]);
    setParticipantFilter([]);
    setTypeFilter([]);
    setStatusFilter([]);
    setDateFilter([]);
  };

  // ----------------------------------------------------------------
  // Render
  // ----------------------------------------------------------------
  return (
    <div className="relative bg-[#F7F9FC] dark:bg-background-dark sm:min-w-[640px] overflow-x-auto max-w-full">
      <div className="h-[300px] w-full bg-navBackground dark:bg-navBackground-dark -z-10 rounded-b-3xl"></div>

      <div className="overflow-x-auto -top-64 relative dark:bg-card-dark dark:text-text-dark bg-[#F7F9FF] p-5 pt-0 rounded-3xl shadow-lg w-11/12 mx-auto min-h-[500px] -mt-10 max-w-[1800px]">
        <div className="flex justify-between items-center">
          <h1 className="my-10 text-xl inter-font text-text">Tasks</h1>
          <button
            onClick={handleToggleFilterForm}
            className="flex gap-2 align-center items-center p-3 pe-5 shadow-sm my-2 ms-auto rounded-2xl bg-white text-muted"
          >
            <div className="rounded-full bg-[#F5F5F5] w-6 h-6 text-xs flex items-center justify-center">
              <FontAwesomeIcon icon={faFilter} className="text-[10px]" />
            </div>
            <div className="text-sm">Filters</div>
          </button>
        </div>

        <div className="relative rounded-3xl">
          {toggleFilterForm && (
            <div className="remove-input-txt-border absolute -mt-5 right-0 max-w-[500px] border rounded-md shadow-lg ms-auto w-2/5 p-5 bg-card my-5 dark:bg-card-dark dark:text-text-dark z-30">
              <div className="flex justify-between align-center items-center">
                <div className="font-bold text-lg">Filters</div>
                <div
                  className="m-1 p-1 pe-2 bg-muted-dark rounded-3xl text-sm flex items-center align-middle gap-2 cursor-pointer text-[11px]"
                  onClick={clearFilters}
                >
                  <FontAwesomeIcon icon={faX} className="text-[11px] ps-2" />
                  <div className="text-xs">Clear Filters</div>
                </div>
              </div>

              {/* Assignee Filter */}
              <h3 className="inter-font text-text mt-3">Assignees</h3>
              <Select
                isMulti
                options={assigneeOptions}
                value={assigneeFilter}
                onChange={handleAssigneeFilterChange}
                placeholder="Filter by Assignee"
              />

              {/* Participant Filter */}
              <h3 className="inter-font text-text mt-3">Participants</h3>
              <Select
                isMulti
                options={participantOptions}
                value={participantFilter}
                onChange={handleParticipantFilterChange}
                placeholder="Filter by Participant"
              />

              {/* Type Filter */}
              <h3 className="inter-font text-text mt-3">Task Type</h3>
              <Select
                isMulti
                options={typeOptions}
                value={typeFilter}
                onChange={handleTypeFilterChange}
                placeholder="Filter by Type"
              />

              {/* Status Filter */}
              <h3 className="inter-font text-text mt-3">Task Status</h3>
              <Select
                isMulti
                options={statusOptions}
                value={statusFilter}
                onChange={handleStatusFilterChange}
                placeholder="Filter by Status"
              />

              {/* Relative Date Filter */}
              <h3 className="inter-font text-text mt-3">Task Relative Date</h3>
              <Select
                isMulti
                options={dateOptions}
                value={dateFilter}
                onChange={handleDateFilterChange}
                placeholder="Filter by Date"
              />
            </div>
          )}

          {/* The filtered tasks are presumably already loaded or passed in */}
          <TasksPageTable
            tasks={tasks}
            avatars={avatars}
            displayTaskCount={true}
            page={currentPage}
            isLoading={isLoading}
            pageSize={pageSize}
            totalCount={totalTasks}
          />

          <div className="flex justify-center align-middle items-center">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalTasks / pageSize) || 0}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TasksPageNew;

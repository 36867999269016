import { DriveFile, DriveItem, Participant, ParticipantYear } from "../interfaces/caseFileDrive/DriveFiles";
import { UserFileDTO, UserFolderDTO } from "../interfaces/file/UserFiles";

export function mapParticipantsToDriveItems(
  participants: Participant[]
): DriveItem[] {
  return participants.map((p) => ({
    id: p.participantId.toString(),
    name: p.participantName,
    isFolder: true, 
    size: p.size, 
    updateDate: p.updateDate
    // treat participants as "folders"
    // Optionally, store any additional info you want:
    // For example, you could store a reference to the years array:
    //   years: p.years
  }));
}

export function mapYearsToDriveItems(years: ParticipantYear[]): DriveItem[] {
  return years.map((y) => ({
    id: y.year.toString(),
    name: y.year.toString(),
    isFolder: true, // we treat each year like a "folder"
    size: y.size, 
    updateDate: y.updateDate
  }));
}

export function mapFilesToDriveItems(files: DriveFile[]): DriveItem[] {
  return files.map((f) => ({
    id: f.fileId,
    name: f.fileName,
    isFolder: false, // these are actual files
    size: f.size, 
    mimeType: f.mimeType, 
    updateDate: f.updateDate, 
    authorFullName: f.authorFullName, 
    authorId: f.authorId
  }));
}

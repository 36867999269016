import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SmallTasksPageTable from "../../tasks/SmallTaskTable";
import TaskList from "../../../../interfaces/task/TaskList";
import Avatar from "../../../../interfaces/avatar/Avatar";
import { getAvatarsFromStorage } from "../../../../services/fileServices/FileService";
import { useAuth } from "../../../../firebase/AuthProvider";
import { fetchTasksWithCache } from "../../../../services/taskServices/TaskService";

const UserProfileTasks: React.FC = () => { 
    const { currentUser } = useAuth();
    const { userId } = useParams();
    const [tasks, setTasks] = useState<TaskList[]>([]);
    const [avatars, setAvatars] = useState<Avatar[] | null>([]);

    useEffect(() => { 
        if (currentUser && userId) { 
            const fetchData = async () => { 
                const authToken = await currentUser.getIdToken();
                const tasksForThisUser = await fetchTasksWithCache(
                    authToken,
                    userId
                  );
                  if ( tasksForThisUser)
                    setTasks(tasksForThisUser)
            }

            fetchData();
        }
    }, [userId])

    useEffect(() => {
        const storedAvatars = getAvatarsFromStorage();
        setAvatars(storedAvatars);
      }, []);

    return <div><SmallTasksPageTable tasks={tasks} avatars={avatars ? avatars : []} displayTaskCount={true}/></div>
};

export default UserProfileTasks;
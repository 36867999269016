import React, { useEffect, useState } from "react";
import { useAuth } from "../../../firebase/AuthProvider";
import { getUserInfoByUserId } from "../../../services/userServices/UserInfoService";
import UserInfo from "../../../interfaces/UserInfo";
import { getAvatarsFromStorage } from "../../../services/fileServices/FileService";
import Avatar from "../../../interfaces/avatar/Avatar";
import { useNavigate } from "react-router-dom";

interface UserFolderCardProps {
  userId: string;
}

const UserFolderCard: React.FC<UserFolderCardProps> = ({ userId }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [avatars, setAvatars] = useState<Avatar[] | null>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser && userId) {
        const authToken = await currentUser.getIdToken();
        const data = await getUserInfoByUserId(userId, authToken);
        if (data) setUserInfo(data);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    const storedAvatars = getAvatarsFromStorage();
    setAvatars(storedAvatars);
  }, []);

  const findAvatarById = (userId: string): string | null => {
    const avatarObj = avatars?.find((avatar) => avatar.id === userId);
    return avatarObj ? `data:image/png;base64,${avatarObj.avatar}` : null;
  };

  const avatarUrl = findAvatarById(userId || "");

  return (
    <div className="bg-white rounded-xl p-2 inter-font shadow-lg w-[200px] border">
      <div className="flex justify-center items-center align-middle my-3 ">
        {avatarUrl ? (
          <div className="shadow-md w-14 h-14 rounded-full border">
            <img
              src={avatarUrl}
              alt={`${userInfo?.displayName}`}
              className="w-14 h-14 rounded-full object-cover shadow-md"
            />
          </div>
        ) : (
          <div className="text-lg bg-gray-100 rounded-full w-14 h-14 flex align-middle items-center justify-center text-center shadow-md border font-bold">
            {userInfo
              ? userInfo?.firstName[0] + userInfo?.lastName[0]
              : "Undefined User"}
          </div>
        )}
      </div>
      {userInfo && (
        <div className="flex flex-col">
          <div className="text-sm mt-1 text-center inter-font font-bold">
            {userInfo.firstName + " " + userInfo.lastName}
          </div>
          <div className="text-xs text-muted text-center">{userInfo.email}</div>
          <div className="flex justify-around align-middle items-center gap-2">
            <button className="text-xs p-2 mt-2 shadow-md text-inactive border bg-white rounded-lg w-full cursor-default"
                // onClick={() => navigate(`/profile/user/${userId}/message`)}
            >
              Message
            </button>
            <button className="text-xs p-2 mt-2 shadow-md hover:shadow-none bg-gray-500 text-white rounded-lg w-full"
                onClick={() => navigate(`/profile/user/${userId}`)}
            >
              View Profile
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserFolderCard;

// src/components/userDrive/RightClickComponent.tsx
import React from "react";
import { faDownload, faEye, faFolderPlus, faPencil, faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DriveItem } from "../../../../../interfaces/caseFileDrive/DriveFiles";

interface ContextMenuProps {
  x: number;
  y: number;
  clickedItem: DriveItem | null; // null means background
  toggleCreateFolder: () => void;
  toggleUploadFile: () => void;
  onRename: (item: DriveItem) => void;
  onDelete: (item: DriveItem) => void;
  onDownload: (item: DriveItem) => void;
  onView: (item: DriveItem) => void;
}

const CustomContextMenu: React.FC<ContextMenuProps> = ({
  x,
  y,
  clickedItem,
  toggleCreateFolder,
  toggleUploadFile,
  onRename,
  onDelete,
  onDownload,
  onView,
}) => {
  const style: React.CSSProperties = {
    top: `${y - 140}px`,
    left: `${x - 300}px`,
    position: "absolute",
    backgroundColor: "white",
    width: "200px",
    zIndex: 1000,
  };

  // Helper booleans
  const isFolder = clickedItem?.isFolder ?? false;
  const isFile = clickedItem?.isFolder === false;

  return (
    <div className="absolute border rounded-lg shadow-xl text-xs" style={style}>
      {/* If user right-clicked on background => show original menu */}
      {clickedItem === null && (
        <div className="flex flex-col">
          <div
            onClick={toggleCreateFolder}
            className="p-2 cursor-pointer flex gap-2 items-center hover:bg-gray-100"
          >
            <FontAwesomeIcon className="text-gray-600" icon={faFolderPlus} />
            <div> Create Folder</div>
          </div>
          <div
            onClick={toggleUploadFile}
            className="p-2 cursor-pointer flex gap-2 items-center hover:bg-gray-100"
          >
            <FontAwesomeIcon className="text-gray-600" icon={faUpload} />
            <div> Upload File</div>
          </div>
        </div>
      )}

      {/* If user right-clicked on a folder => show folder operations */}
      {isFolder && clickedItem && (
        <div className="flex flex-col">
          <div
            onClick={() => onRename(clickedItem)}
            className="p-2 cursor-pointer hover:bg-gray-100 text-text flex items-center align-middle gap-2"
          >
            <FontAwesomeIcon className="text-gray-500" icon={faPencil} />
            Rename Folder
          </div>
          <div
            onClick={() => onDelete(clickedItem)}
            className="p-2 cursor-pointer hover:bg-gray-100 flex items-center align-middle gap-2 text-danger"
          >
            <FontAwesomeIcon className="" icon={faTrash} />
            Delete Folder
          </div>
        </div>
      )}

      {/* If user right-clicked on a file => show file operations */}
      {isFile && clickedItem && (
        <div className="flex flex-col inter-font text-xs">
          <div
            onClick={() => onDownload(clickedItem)}
            className="p-2 cursor-pointer hover:bg-gray-100 text-text flex items-center align-middle gap-2"
          >
            <FontAwesomeIcon  className="text-gray-500" icon={faDownload} />
            Download
          </div>
          <div
            onClick={() => onView(clickedItem)}
            className="p-2 cursor-pointer hover:bg-gray-100 text-text flex items-center align-middle gap-2"
          >
            <FontAwesomeIcon className="text-gray-500" icon={faEye} />
            View
          </div>
          <div
            onClick={() => onRename(clickedItem)}
            className="p-2 cursor-pointer hover:bg-gray-100 text-text flex items-center align-middle gap-2"
          >
            <FontAwesomeIcon className="text-gray-500" icon={faPencil} />
            Rename
          </div>
          <div
            onClick={() => onDelete(clickedItem)}
            className="p-2 cursor-pointer hover:bg-gray-100 flex items-center align-middle gap-2 text-danger"
          >
            <FontAwesomeIcon className="" icon={faTrash} />
            Delete
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomContextMenu;
